import axios from "axios";
import config from "shared/config";

import { Config } from "./configState";

export interface ElevateConfig {
  QUEUES: Record<string, string>;
}
export const fetchElevateConfig = async (): Promise<Config> => {
  const url = new URL("env/config", config.elevateUrl);
  const response = await axios.get<ElevateConfig>(url.toString());
  const { QUEUES: queues } = response.data;
  return { queues };
};
