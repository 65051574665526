import { combineReducers } from "@reduxjs/toolkit";
import { baseApi } from "shared/api/baseApi";

import { configSlice } from "./config";
import { currentUserSlice } from "./currentUser";
import { institutionSlice } from "./institution";
import { annotationsSlice } from "./player/annotations";
import { epubPageTrackerSlice } from "./player/epubPageTracker";
import { playerReadPositionSlice } from "./player/playerReadPosition";
import { playerSelectionsSlice } from "./player/playerSelections";
import { playerSettingsSlice } from "./player/playerSettings";
import { wordDataSlice } from "./player/wordData";
import { cookiePreferencesSlice } from "./cookiePreferences";

const reducers = {
  config: configSlice.reducer,
  currentUser: currentUserSlice.reducer,
  institution: institutionSlice.reducer,
  playerSelections: playerSelectionsSlice.reducer,
  playerSettings: playerSettingsSlice.reducer,
  playerReadPositions: playerReadPositionSlice.reducer,
  epubPageTracker: epubPageTrackerSlice.reducer,
  annotations: annotationsSlice.reducer,
  wordData: wordDataSlice.reducer,
  cookiePreference: cookiePreferencesSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
};

export const rootReducer = combineReducers(reducers);
export type ReducerTypes = typeof reducers;
