import { useEffect } from "react";

export const useTitle = (...title: string[]): void => {
  useEffect(() => {
    const prevTitle = document.title;

    document.title = title
      .concat(["Talis Elevate"])
      .filter((title) => title !== "")
      .join(" · ");

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
};
