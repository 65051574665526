export type ColourTheme = {
  id: string;
  backgroundColour: string;
  textColour: string;
};

export const getColourThemes = (): ColourTheme[] => {
  return [
    {
      id: "settings.colourPreferences-white",
      backgroundColour: "#FFFFFF",
      textColour: "#000000",
    },
    {
      id: "settings.colourPreferences-paper",
      backgroundColour: "#F9F8F6",
      textColour: "#000000",
    },
    {
      id: "settings.colourPreferences-sepia",
      backgroundColour: "#F1E7D0",
      textColour: "#000000",
    },
  ];
};

export const getColourTheme = (id: string): ColourTheme | undefined => {
  const allThemes = getColourThemes();
  return allThemes.find((theme) => theme.id === id);
};

export const defaultColourTheme = getColourThemes()[0];
