export enum CookiePreference {
  OptIn = "true", // User has opted into cookies
  OptOut = "false", // User has opted out of cookies
  Unknown = "unknown", // User's preference has not yet been loaded
  NotSet = "not_set", // The user has not made a choice
}

export type CookiePreferencesState = {
  acceptCookies: CookiePreference;
  cookieConsentDecisionDate: Date | null;
};

export const initialCookiePreferencesState: CookiePreferencesState = {
  acceptCookies: CookiePreference.Unknown,
  cookieConsentDecisionDate: null,
};
