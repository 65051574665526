import { connect } from "react-redux";
import { RootState } from "shared/redux/store";

import AuthenticatedPage from "./AuthenticatedPage";
import { selectCurrentUserState } from "../redux/currentUser";

const mapStateToProps = (state: RootState) => {
  return {
    currentUserState: selectCurrentUserState(state),
  };
};

export default connect(mapStateToProps)(AuthenticatedPage);
