export enum AnnotationFeedType {
  PersonalNotes,
  ClassComments,
}

export type SelectedSearchResult = {
  page: number;
  searchTerm: string;
  searchResultText: string;
  searchResultIndex: number;
};

export type PlayerSelectionsState = {
  selectedAnnotationFeed: AnnotationFeedType;
  showAnnotations: boolean;
  submittedSearchTerm?: string;
  selectedSearchResult?: SelectedSearchResult;
};

export const initialPlayerSelectionsState: PlayerSelectionsState = {
  selectedAnnotationFeed: AnnotationFeedType.ClassComments,
  showAnnotations: true,
};
