import "./core/fontAwesome";

import {
  addAccessToken,
  addJsonApiHeaders,
  addRetryOnAuthenticationFailure,
} from "core/axiosInterceptors";
import Zendesk from "core/Zendesk";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import AntiClickjack from "shared/AntiClickjack";
import routesWithClickjackProtectionDisabled from "shared/AntiClickjack/routesWithClickjackProtectionDisabled";
import routesWithExternalClickjackProtection from "shared/AntiClickjack/routesWithExternalClickjackProtection";
import config from "shared/config";
import ErrorPage from "shared/ErrorPage";
import { logComponentError } from "shared/log";

import AppRouter from "./routing/AppRouter";
import NotificationProvider from "./shared/Notifications/NotificationProvider";
import GoogleAnalytics from "core/GoogleAnalytics";
import axios from "axios";
import ElevateProductFruits from "shared/ElevateProductFruits";

addAccessToken(axios);
addRetryOnAuthenticationFailure(axios);
addJsonApiHeaders(axios);

const sendError = (error: Error, info: { componentStack: string }) => {
  logComponentError(error, info.componentStack);
};

function App(): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onError={sendError}>
      <BrowserRouter>
        <Suspense fallback={null}>
          <NotificationProvider>
            <AntiClickjack
              allowedRoutes={[
                ...routesWithExternalClickjackProtection,
                ...routesWithClickjackProtectionDisabled,
              ]}
              windowSelf={window.self}
              windowTop={window.top}
              clickjackingProtectionDisabled={
                config.clickjackingProtectionDisabled
              }
            >
              <ElevateProductFruits />
              <Zendesk />
              <GoogleAnalytics />
              <AppRouter />
            </AntiClickjack>
          </NotificationProvider>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
