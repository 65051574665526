/*
 For a description of why this defence is necessary, please look at the following
 OWASP article:

 https://cheatsheetseries.owasp.org/cheatsheets/Clickjacking_Defense_Cheat_Sheet.html#best-for-now-legacy-browser-frame-breaking-script

 This component uses similar code that has been made more friendly for React
 but serves the same purpose.
*/

import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import { ReactComponent as Authentication } from "./authentication.svg";
export interface AntiClickjackProps {
  allowedRoutes: string[];
  children?: React.ReactNode;
  windowSelf: Window;
  windowTop: Window | null;
  clickjackingProtectionDisabled: boolean;
}

const AntiClickjack = ({
  allowedRoutes,
  children,
  windowSelf,
  windowTop,
  clickjackingProtectionDisabled,
}: AntiClickjackProps): JSX.Element => {
  const [allow, setAllow] = useState<boolean | null>(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (clickjackingProtectionDisabled) {
      setAllow(true);
      return;
    }

    if (allowedRoutes.some((route) => matchPath(route, location.pathname))) {
      setAllow(true);
      return;
    }

    setAllow(windowSelf === windowTop);
  }, [
    location,
    allowedRoutes,
    windowSelf,
    windowTop,
    clickjackingProtectionDisabled,
  ]);

  if (allow === true) {
    return <>{children}</>;
  }

  if (allow === false) {
    return (
      <div className="text-center">
        {<Authentication />}
        <h1>
          {t(
            "antiClickJack-securityError-header",
            "The resource cannot be displayed here",
          )}
        </h1>
        <p>
          {
            <Trans i18nKey={"antiClickJack-securityError-cannotLoad"}>
              To protect the security of the information you enter into this
              website, it cannot be displayed here. To find out more about the
              best ways to link or embed Talis Elevate content read our{" "}
              <a href="https://support.talis.com/hc/en-gb/articles/17338228227485-Linking-or-Embedding-Talis-Elevate-content-into-my-VLE">
                support article
              </a>
            </Trans>
          }
        </p>
        <p>
          {t(
            "antiClickJack-securityError-canOpenInNewWindow",
            "You can still safely open the resource in a new window.",
          )}
        </p>
        <p>
          <a
            href={windowSelf.location.toString()}
            target="_blank"
            rel="noreferrer"
            className="btn btn-small btn-link"
          >
            {t(
              "antiClickJack-securityError-btn",
              "Open resource in new window",
            )}
          </a>
        </p>
      </div>
    );
  }

  return <></>;
};

export default AntiClickjack;
