import { ReactElement, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "shared/redux/hooks";
import { setCurrentUser } from "shared/redux/currentUser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorPage from "shared/ErrorPage";
import { completeRedirectAuthentication } from "@technologyfromsage/persona-padlock";
import { logError } from "shared/log";

export type RedirectAuthAppState = {
  target: string;
};

const RedirectAuthPage = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticating = useRef(false);
  const { t } = useTranslation();

  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    const completeAuthentication = async () => {
      isAuthenticating.current = true;

      try {
        const { loginData, appState } =
          await completeRedirectAuthentication<RedirectAuthAppState>();

        dispatch(setCurrentUser(loginData));
        navigate(appState?.target ?? "/", { replace: true });
      } catch (error) {
        logError(error);
        setError(
          Error(
            t(
              "redirectAuthPage.completeRedirectLogin-error",
              "An error occurred while logging you in.",
            ),
          ),
        );
      }
    };

    if (!isAuthenticating.current) {
      completeAuthentication();
    }
  }, [dispatch, navigate, t]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  return <></>;
};

export default RedirectAuthPage;
