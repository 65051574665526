import { AxiosInstance } from "axios";
import config from "shared/config";
import {
  clearCurrentUser,
  fetchFreshTokens,
  selectCurrentUser,
  updateTokens,
} from "shared/redux/currentUser";
import { store } from "shared/redux/store";
import { logError } from "shared/log";

export const addAccessToken = (axios: AxiosInstance): void => {
  axios.interceptors.request.use(
    (request) => {
      const authToken = getAuthToken();
      return {
        ...request,
        headers: {
          ...request.headers,
          ...(authToken && { Authorization: `Bearer ${authToken}` }),
        },
      };
    },
    (error) => Promise.reject(error),
  );
};

export const addRetryOnAuthenticationFailure = (axios: AxiosInstance): void => {
  let retriesInProgress: string[] = [];

  axios.interceptors.response.use(
    (response) => response,
    async (responseError) => {
      const retryUrl = retriesInProgress.find(
        (url) => url === responseError.config.url,
      );

      if (retryUrl) {
        retriesInProgress = retriesInProgress.filter((url) => url !== retryUrl);
        throw responseError;
      }

      const { response } = responseError;

      if (!response || response.status === 401) {
        retriesInProgress.push(responseError.config.url);
        try {
          await requestFreshTokens();

          return axios(responseError.config);
        } catch (error) {
          logError(error);

          removeExpiredUser();

          throw responseError;
        }
      }

      throw responseError;
    },
  );
};

const requestFreshTokens = async (): Promise<void> => {
  const state = store.getState();
  const currentUser = selectCurrentUser(state);

  const freshTokens = await fetchFreshTokens(
    currentUser.guid,
    currentUser.refreshToken,
  );

  store.dispatch(
    updateTokens({
      accessToken: freshTokens.accessToken,
      refreshToken: freshTokens.refreshToken,
    }),
  );
};

const removeExpiredUser = (): void => {
  // this will trigger AuthenticatedPage to render which will redirect to login
  store.dispatch(clearCurrentUser());
};

const getAuthToken = (): string | null => {
  const { data: { accessToken } = {} } = store.getState().currentUser;
  return accessToken || null;
};

export const addJsonApiHeaders = (axios: AxiosInstance): void => {
  axios.interceptors.request.use(
    (request) => {
      if (request.url?.startsWith(`${config.elevateUrl}1/`)) {
        return {
          ...request,
          headers: {
            ...request.headers,
            ...{ "Content-Type": "application/vnd.api+json" },
          },
        };
      }
      return request;
    },
    (error) => Promise.reject(error),
  );
};
