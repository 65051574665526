export const buildRedirectUrl = (
  instCode: string,
  redirectUri: string | null,
  defaultRedirect: string,
): string => {
  const uri = redirectUri || defaultRedirect;

  if (uri.indexOf("/") !== 0) {
    return `/${instCode}/${uri}`;
  }

  return `/${instCode}${uri}`;
};
