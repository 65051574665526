import { Navigate, useLocation } from "react-router-dom";
import config from "shared/config";
import ExternalRedirect from "shared/ExternalRedirect";

import { INST_CODE_FOR_SILENT_AUTH_KEY } from "shared/localStorage/keys";

const TextbookLoginHandler = (): JSX.Element => {
  const { pathname } = useLocation();

  const storedInstCode = localStorage.getItem(INST_CODE_FOR_SILENT_AUTH_KEY);

  if (!storedInstCode) {
    return <Navigate to={`/catalyst?redirectUri=${pathname}`} />;
  }

  return (
    <ExternalRedirect to={`${config.elevateUrl}${storedInstCode}${pathname}`} />
  );
};

export default TextbookLoginHandler;
