import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { Institution } from "shared/institution";
import config from "shared/config";
import "./GoogleAnalytics.scss";
import { CookiePreference } from "shared/redux/cookiePreferences";
import { CurrentUserData } from "shared/redux/currentUser";
import useCookiePreferences from "shared/customHooks/useCookiePreferences";

export type GoogleAnalyticsProps = {
  institution?: Institution;
  currentUser?: CurrentUserData;
  inIframe: boolean;
};

const GoogleAnalytics = ({
  institution,
  currentUser,
  inIframe,
}: GoogleAnalyticsProps) => {
  const { t } = useTranslation();
  const { saveCookiePreference, cookiePreference } = useCookiePreferences();

  const trackInst =
    institution && institution.features?.enableGoogleAnalytics === true;
  const setCookiePreference = (preference: CookiePreference) => {
    if (currentUser) {
      saveCookiePreference(currentUser.guid, preference);
    }
  };

  useEffect(() => {
    if (!trackInst) {
      return;
    }

    if (cookiePreference === CookiePreference.Unknown) {
      ReactGA.gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 2000,
      });
      ReactGA.initialize(config.googleAnalyticsPropertyId);
    }
  }, [institution, trackInst, cookiePreference]);

  useEffect(() => {
    if (
      cookiePreference !== CookiePreference.OptIn &&
      cookiePreference !== CookiePreference.OptOut
    ) {
      return;
    }

    const contentModeBehaviour =
      cookiePreference === CookiePreference.OptIn ? "granted" : "denied";

    ReactGA.gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: contentModeBehaviour,
    });
  }, [cookiePreference]);

  if (!trackInst || inIframe || cookiePreference !== CookiePreference.NotSet) {
    return <></>;
  }

  return (
    <div className="c-cookie-banner fixed-top text-center py-3 d-flex align-items-center justify-content-center">
      <p className="my-0 mx-2">
        {t(
          "GoogleAnalytics-cookieBanner",
          "We use Google Analytics to help us improve Talis Elevate, cookies can help us understand how users interact with it. But these cookies are only set if you accept them.",
        )}
      </p>
      <div className="d-flex">
        <button
          onClick={() => setCookiePreference(CookiePreference.OptIn)}
          className="btn btn-primary mx-1 pendo-googleAnalytics-accept"
          type="button"
        >
          {t("GoogleAnalytics-cookieBanner-acceptBtn", "Accept cookies")}
        </button>
        <button
          onClick={() => setCookiePreference(CookiePreference.OptOut)}
          className="btn btn-default mx-1 pendo-googleAnalytics-reject"
          type="button"
        >
          {t("GoogleAnalytics-cookieBanner-rejectBtn", "Reject cookies")}
        </button>
      </div>
    </div>
  );
};

export default GoogleAnalytics;
