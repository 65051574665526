import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  initialCookiePreferencesState,
  CookiePreferencesState,
} from "./cookiePreferencesState";

export const cookiePreferencesSlice = createSlice({
  name: "cookiePreferencesSlice",
  initialState: initialCookiePreferencesState,
  reducers: {
    setCookiePreference: (
      state,
      action: PayloadAction<CookiePreferencesState>,
    ) => {
      state.acceptCookies = action.payload.acceptCookies;
      state.cookieConsentDecisionDate =
        action.payload.cookieConsentDecisionDate;
    },
  },
});

export const { setCookiePreference } = cookiePreferencesSlice.actions;
