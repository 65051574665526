import { AriaRole } from "react";

export type AlertProps = {
  level: AlertLevel;
  message: string | React.ReactNode;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement> & {
    "data-bs-dismiss"?: string;
  };
};

export enum AlertLevel {
  Danger = "alert-danger",
  Info = "alert-info",
  Success = "alert-success",
  Warning = "alert-warning",
}

const AlertLevelToAriaRole = new Map<AlertLevel, AriaRole | undefined>([
  [AlertLevel.Danger, "alert"],
  [AlertLevel.Info, undefined],
  [AlertLevel.Success, "status"],
  [AlertLevel.Warning, "status"],
]);

const getAriaRole = (alertLevel: AlertLevel): AriaRole | undefined =>
  AlertLevelToAriaRole.get(alertLevel);

const Alert = ({ level, message, buttonProps }: AlertProps): JSX.Element => {
  return (
    <div
      className={`alert ${level} d-flex justify-content-between align-items-center`}
      role={getAriaRole(level)}
    >
      {message}
      {buttonProps && (
        <button
          type="button"
          {...buttonProps}
          className={`btn btn-alert btn-outline-light ${buttonProps.className}`}
        >
          {buttonProps.children}
        </button>
      )}
    </div>
  );
};

export default Alert;
