import { createSlice } from "@reduxjs/toolkit";

import wordDataApi from "./wordData";
import {
  calculateWordPosition,
  getDimensionsForPage,
  getLinkForWord,
} from "./wordDataHelpers";
import { WordDataState, WordMap, initialWordDataState } from "./wordDataState";

export const wordDataSlice = createSlice({
  name: "wordData",
  initialState: initialWordDataState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      wordDataApi.endpoints.getWordData.matchFulfilled,
      (
        state: WordDataState,
        {
          payload: { fileId, startIndex: page, words = [], links = [] },
          meta: {
            arg: {
              originalArgs: { file },
            },
          },
        },
      ): WordDataState => {
        const wordsForPage = words[page] || [];
        const linksForPage = links[page] || [];
        const pageDimensions = getDimensionsForPage(file, page);

        const wordMap: WordMap = wordsForPage.map((word, wordIndex) => {
          const position = calculateWordPosition(word, pageDimensions);
          const link = getLinkForWord(
            word,
            wordsForPage[word.wi - 1] || null,
            wordsForPage[word.wi + 1] || null,
            linksForPage,
          );
          return { word, wordIndex, position, link };
        });

        return {
          ...state,
          [fileId]: {
            ...state[fileId],
            [page]: wordMap,
          },
        };
      },
    );
  },
});
