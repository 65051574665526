import axios from "axios";
import config from "shared/config";
import { Institution } from "shared/institution";

export const fetchInstitution = async (
  instCode: string,
): Promise<Institution> => {
  const url = new URL(`institutions/${instCode}`, config.elevateUrl);
  const response = await axios.get<Institution>(url.toString());
  return response.data;
};
