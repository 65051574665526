import { RootState } from "shared/redux/store";
import { connect } from "react-redux";
import { selectCurrentUserState } from "shared/redux/currentUser";
import ElevateProductFruits from "./ElevateProductFruits";
import { selectInstitution } from "shared/redux/institution";

const mapStateToProps = (state: RootState) => {
  return {
    currentUser: selectCurrentUserState(state).data,
    institution: selectInstitution(state),
  };
};

export default connect(mapStateToProps)(ElevateProductFruits);
