import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons";
import {
  faChartBar,
  faCheck,
  faClock,
  faClosedCaptioning,
  faCloudDownload,
  faCloudUpload,
  faCog,
  faCommentAlt,
  faComments,
  faCompress,
  faExpand,
  faFlag,
  faList,
  faMinus,
  faPause,
  faPencil,
  faPlay,
  faPlus,
  faSpinnerThird,
  faStickyNote,
  faThLarge,
  faTimes,
  faTimesCircle as faTimesCircleLight,
  faVolume,
  faVolumeMute,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowAltCircleLeft,
  faEllipsisH,
  faEllipsisV,
  faExternalLink,
  faEyeSlash,
  faFile,
  faInfoCircle,
  faReply,
  faSearch,
  faText,
  faVideo,
  faVolumeUp,
  faThumbsUp as farThumbsUp,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faCircle,
  faFolders,
  faSort,
  faSortDown,
  faSortUp,
  faSquare,
  faTimesCircle,
  faUser,
  faUserGraduate,
  faUserSecret,
  faClosedCaptioning as fasClosedCaptioning,
  faThumbsUp as fasThumbsUp,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faArrowDown,
  faArrowUp,
  faArrowAltCircleLeft,
  faChartBar,
  faCheck,
  faCircle,
  faClosedCaptioning,
  fasClosedCaptioning,
  faCloudDownload,
  faCloudUpload,
  faCog,
  faCommentAlt,
  faComments,
  faCompress,
  faEllipsisH,
  faEllipsisV,
  faExpand,
  faExternalLink,
  faFile,
  faFolders,
  faFlag,
  faInfoCircle,
  faList,
  faMinus,
  faPause,
  faPencil,
  faPlay,
  faPlus,
  faQuestionCircle,
  faReply,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSpinnerThird,
  faSquare,
  faStickyNote,
  faText,
  farThumbsUp,
  fasThumbsUp,
  faThLarge,
  faTimes,
  faClock,
  faTimesCircleLight,
  faTimesCircle,
  faUser,
  faUserGraduate,
  faUserSecret,
  faVideo,
  faVolume,
  faVolumeMute,
  faVolumeUp,
  faEyeSlash,
);
