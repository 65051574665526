import { logError } from "shared/log";

import type { RootState } from "./store";

export const REDUX_STORAGE_KEY = "reduxState";

export const loadReduxState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    logError(err);

    return undefined;
  }
};

export const saveReduxState = <T extends keyof RootState>(
  storageKey: T,
  stateToPersist: RootState[T],
): void => {
  const existingState = loadReduxState();

  const newState: Partial<RootState> = {
    ...existingState,
    [storageKey]: stateToPersist,
  };

  try {
    const serializedState = JSON.stringify(newState);
    localStorage.setItem(REDUX_STORAGE_KEY, serializedState);
  } catch (err) {
    logError(err);
  }
};
