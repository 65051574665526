import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/redux/store";

import { ConfigState } from "./configState";

const selectConfigState = (state: RootState): ConfigState => state.config;

export const selectConfigIsLoading = createSelector(
  [selectConfigState],
  (configState) => !!configState.isLoading,
);

const selectCurrentConfig = createSelector(
  [selectConfigState],
  (configState) => configState.data,
);

export const selectQueuesFromConfig = createSelector(
  [selectCurrentConfig],
  (config) => (config ? config.queues : {}),
);
