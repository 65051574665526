export type MissingMock = Error | string;

export class MissingMocksError extends Error {
  constructor(warnings: MissingMock[]) {
    super(MissingMocksError.formatMessages(warnings));
  }

  static formatMessages(missingMocks: MissingMock[]): string {
    if (missingMocks.length === 1) {
      return MissingMocksError.getMessage(missingMocks[0]);
    }

    let message = "Multiple missing mocks were encountered:\n\n";
    for (const missingMock of missingMocks) {
      message += MissingMocksError.getMessage(missingMock) + "\n\n";
    }

    return message;
  }

  static getMessage(missingMock: MissingMock): string {
    if (missingMock instanceof Error) {
      return missingMock.message;
    } else {
      return missingMock;
    }
  }
}

export const isAxiosMockAdaptorMissingMockError = (
  message?: string,
): boolean => {
  if (!message) {
    return false;
  }
  return message.includes("Could not find mock for");
};
