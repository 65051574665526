import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { initialEpubPageTrackerState } from "./epubPageTrackerState";

export const epubPageTrackerSlice = createSlice({
  name: "epubPageTracker",
  initialState: initialEpubPageTrackerState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<string>) => {
      state.pageNumber = action.payload;
    },
  },
});

export const { setPageNumber } = epubPageTrackerSlice.actions;
