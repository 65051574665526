import { createSelector } from "@reduxjs/toolkit";
import { selectInstitution } from "shared/redux/institution";
import { RootState } from "shared/redux/store";

import { isAcademic, isAdmin, isSupportMode } from "./currentUserHelper";
import { CurrentUserState } from "./currentUserState";

export const selectCurrentUserState = (state: RootState): CurrentUserState =>
  state.currentUser;

export const selectCurrentUser = createSelector(
  [selectCurrentUserState],
  (currentUserState) => {
    if (!currentUserState.data) {
      throw new Error("No user found in state");
    }

    return currentUserState.data;
  },
);

export const selectUserGuid = createSelector(
  [selectCurrentUser],
  (user) => user.guid,
);

export const selectUserToken = createSelector(
  [selectCurrentUser],
  (user) => user.accessToken,
);

export const selectSessionId = createSelector(
  [selectCurrentUser],
  (user) => user.sessionId,
);

export const selectIsAdmin = createSelector(
  [selectCurrentUserState],
  ({ data: currentUser }) => {
    if (!currentUser) {
      return false;
    }
    return isAdmin(currentUser);
  },
);

export const selectIsAcademic = createSelector(
  [selectCurrentUserState, selectInstitution],
  ({ data: currentUser }, institution) => {
    if (!currentUser || !institution) {
      return false;
    }
    return isAcademic(currentUser, institution.shortCode);
  },
);

export const selectIsStudent = createSelector(
  [selectIsAcademic, selectIsAdmin],
  (isAcademic, isAdmin) => !isAcademic && !isAdmin,
);

export const selectIsSupportMode = createSelector(
  [selectCurrentUserState, selectInstitution],
  ({ data: currentUser }, institution) => {
    if (!currentUser || !institution) {
      return false;
    }
    return isSupportMode(currentUser, institution.shortCode);
  },
);
