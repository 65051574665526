export type FontPreference = {
  id: string;
  class: string;
  previewFontSize: number;
};

export const getFonts = (): FontPreference[] => {
  return [
    {
      id: "settings.fontPreferences-arial",
      class: "show-default-font",
      previewFontSize: 22,
    },
    {
      id: "settings.fontPreferences-georgia",
      class: "show-georgia-font",
      previewFontSize: 22,
    },
    {
      id: "settings.fontPreferences-openDyslexic",
      class: "show-open-dyslexic-font",
      previewFontSize: 19,
    },
  ];
};

export const getFont = (id: string): FontPreference | undefined => {
  const allFonts = getFonts();
  return allFonts.find((font) => font.id === id);
};

export const defaultFontPreference = getFonts()[0];
