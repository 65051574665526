import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import Pseudo from "i18next-pseudo";
import { initReactI18next } from "react-i18next";

export const DEFAULT_LOCALE = "en-GB";

i18n
  .use(
    new Pseudo({
      languageToPseudo: "pseudo",
    }),
  )
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: DEFAULT_LOCALE,
    fallbackLng: false,
    interpolation: {
      escapeValue: false, // react is already safe from xss
    },
    backend: {
      // replace with "/translations/{{lng}}/{{ns}}.json" if we ever want translations
      loadPath: () => false,
    },
    postProcess: ["pseudo"],
    returnNull: false,
  });

export default i18n;
