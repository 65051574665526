/* eslint-disable jsx-a11y/no-static-element-interactions */
import "./pageNotFound.scss";

import classNames from "classnames";
import { KeyboardEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useTitle from "shared/customHooks/useTitle";

const PageNotFound = (): JSX.Element => {
  const { t } = useTranslation();

  useTitle(t("pageNotFound.title", "Page not found"));

  const [otis, setOtis] = useState(false);
  let keypresses: string[] = [];
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    keypresses.push(event.key);
    if (keypresses.length === 4) {
      if (keypresses.join("") === "otis") {
        setOtis(true);
      } else {
        keypresses = [];
        setOtis(false);
      }
    }
  };

  return (
    <div className="t-error" onKeyPress={handleKeyDown} tabIndex={-1}>
      <main className="c-error-page__container">
        <h1 className="c-error-page__header">
          {t("pageNotFound.heading", "Page not found")}
        </h1>
        <h2 className="c-error-page__subheader">
          {t(
            "pageNotFound.subheading",
            "We can't find the page you're looking for",
          )}
        </h2>

        <hr />

        <section className="c-error-page__context">
          <p>
            <strong>
              {t(
                "pageNotFound.help-needFurtherSupport",
                "Need further support?",
              )}
            </strong>
          </p>

          <div
            className={classNames("c-easter-egg", {
              "c-easter-egg-activated": otis,
            })}
          >
            <p>
              <Trans i18nKey="pageNotFound.error-raiseSupportTicket">
                You can{" "}
                <a
                  href="mailto:support@talis.com"
                  className="pendo-pageNotFound-raiseSupportTicket"
                >
                  raise a support ticket
                </a>{" "}
                and we will be in touch
              </Trans>
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PageNotFound;
