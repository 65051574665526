import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Institution } from "shared/institution";
import shouldHideZendesk from "./ZendeskHelper/shouldHideZendesk";

export type ZendeskProps = {
  institution: Institution | undefined;
};

const Zendesk = ({ institution }: ZendeskProps): null => {
  const location = useLocation();
  const disableZendesk = institution?.features?.disableZendesk;

  useEffect(() => {
    const zendesk = window.zE;

    if (!zendesk) {
      return;
    }

    if (shouldHideZendesk(location.pathname) || disableZendesk) {
      zendesk("messenger", "hide");
    } else {
      zendesk("messenger", "show");
    }
  }, [disableZendesk, location]);

  return null;
};

export default Zendesk;
