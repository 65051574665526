import React from "react";
import { ResourceType } from "shared/api/types/resources";
import config from "shared/config";

export type RouteParams = {
  instCode: string;
  children: React.ReactNode;
};

export const getPlayerUrl = (
  resourceId: string,
  moduleId: string,
  institutionCode: string,
): string => {
  const path = `${institutionCode}/player/modules/${moduleId}/resources/${resourceId}`;
  const url = new URL(path, config.hostUrl);

  return url.toString();
};

export const generateUrl = (
  path: string,
  params?: Record<string, string | number | boolean | undefined>,
  rootPath = config.elevateUrl,
): string => {
  const url = new URL(path, rootPath);
  if (params) {
    for (const property in params) {
      const value = params[property];
      if (value !== undefined) {
        url.searchParams.append(property, value.toString());
      }
    }
  }
  return url.toString();
};

export const getThumbnailUrl = (resourceId: string): string =>
  generateUrl(`resources/${resourceId}/thumbnail`);

export const isAbsoluteUrl = (url: string): boolean => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return true;
  }

  try {
    new URL(url); // relative URLs throw without the second baseURL parameter set
    return true;
  } catch (error) {
    return false;
  }
};

export const getPresignedProxyPath = (
  path: string,
  accessToken: string,
): string =>
  generateUrl(
    "/files/presignedUrl",
    {
      redirect: true,
      depotUrl: path,
      access_token: accessToken,
    },
    config.elevateUrl,
  );

export enum PlayerUrlType {
  Resources = "resources",
  Textbooks = "textbooks",
  Epubs = "epubs",
  Media = "media",
}

export type CreatePlayerUrlOptions = {
  instCode: string;
  moduleId: string;
  resourceId: string;
  urlType: PlayerUrlType;
  searchParams?: URLSearchParams;
};

export const createPlayerUrl = (options: CreatePlayerUrlOptions): string => {
  const { instCode, moduleId, resourceId, urlType, searchParams } = options;
  const search = searchParams ? `?${searchParams}` : "";

  return `/${instCode}/player/modules/${moduleId}/${urlType}/${resourceId}${search}`;
};

export const getPlayerUrlTypeForResourceType = (
  resourceType: ResourceType,
  isInstitutionalAccessModule = false,
): PlayerUrlType => {
  switch (resourceType) {
    case ResourceType.IngestedDocument:
    case ResourceType.IngestedImage:
    case ResourceType.CopyrightClearanceDocument:
    case ResourceType.Resource:
      return PlayerUrlType.Resources;

    case ResourceType.Textbook:
      return isInstitutionalAccessModule
        ? PlayerUrlType.Textbooks
        : PlayerUrlType.Resources;

    case ResourceType.IngestedEPub:
    case ResourceType.EPubTextbook:
      return PlayerUrlType.Epubs;

    case ResourceType.IngestedAudio:
    case ResourceType.IngestedVideo:
    case ResourceType.YouTubeVideo:
      return PlayerUrlType.Media;
  }
};
