import Home from "core/Home";
import LoggedOut from "core/LoggedOut";
import PageNotFound from "core/PageNotFound";
import CatalystLoginHandler from "core/splash/CatalystLoginHandler";
import CatalystSplashScreen from "core/splash/CatalystSplashScreen";
import ElevateSplashScreen from "core/splash/ElevateSplashScreen";
import SplashScreenLoginHandler from "core/splash/SplashScreenLoginHandler";
import { lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AuthenticatedPage from "shared/AuthenticatedPage";
import Loader from "shared/Loader";
import { fetchElevateConfigThunk } from "shared/redux/config";
import { AuthScope } from "shared/redux/currentUser";
import { useAppDispatch } from "shared/redux/hooks";
import RedirectAuthPage from "shared/AuthenticatedPage/RedirectAuthPage";

const LazyAdminRouter = lazy(() => import("routing/AdminRouter"));
const LazyTenantedRouter = lazy(() => import("routing/TenantedRouter"));

interface AppRouterProps {
  isConfigLoading: boolean;
}
const AppRouter = ({ isConfigLoading }: AppRouterProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchElevateConfigThunk());
  }, [dispatch]);

  const onPageChange = (pageUrl: string) => {
    if (typeof ineum !== "undefined") {
      ineum("page", pageUrl);
    }
  };

  useEffect(() => {
    onPageChange(`${location.pathname}${location.search}`);
  }, [location]);

  if (isConfigLoading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="logged-out" element={<LoggedOut />} />
      <Route
        path="admin/*"
        element={
          <AuthenticatedPage requiredScopes={[AuthScope.Support]}>
            <LazyAdminRouter />
          </AuthenticatedPage>
        }
      />
      <Route
        path="splash"
        element={
          <SplashScreenLoginHandler splashScreen={ElevateSplashScreen} />
        }
      />
      <Route path="textbooks/:isbn" element={<CatalystLoginHandler />} />
      <Route
        path="catalyst"
        element={
          <SplashScreenLoginHandler splashScreen={CatalystSplashScreen} />
        }
      />
      <Route path=":instCode/*" element={<LazyTenantedRouter />} />
      <Route path="/redirect-authentication" element={<RedirectAuthPage />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRouter;
