import { find } from "linkifyjs";
import {
  DocumentPageDimension,
  DocumentResourceFile,
  Link,
  Word,
} from "shared/api/types/resources/ingested";

import { WordPosition } from "./wordDataState";

export const calculateWordPosition = (
  word: Word,
  pageDimensions: DocumentPageDimension,
): WordPosition => {
  const [start, end] = word.l;
  const [startX, startY] = start;
  const [endX, endY] = end;
  const wordWidth = endX - startX;
  const wordHeight = endY - startY;

  const left = (startX / pageDimensions.width) * 100;
  const top = (startY / pageDimensions.height) * 100;

  const width = (wordWidth / pageDimensions.width) * 100;
  const height = (wordHeight / pageDimensions.height) * 100;

  return { left, top, height, width };
};

export const getDimensionsForPage = (
  documentFile: DocumentResourceFile,
  page: number,
): DocumentPageDimension => {
  if (
    documentFile.presentationMeta.page_dimensions.exceptions &&
    documentFile.presentationMeta.page_dimensions.exceptions[page]
  ) {
    return documentFile.presentationMeta.page_dimensions.exceptions[page];
  }

  return documentFile.presentationMeta.page_dimensions.default;
};

const linkOverlapsWord = (word: Word, link: Link): boolean => {
  const fudgeFactor = 3;

  const [[wordMinX, wordMinY], [wordMaxX, wordMaxY]] = word.l;
  const [[linkMinX, linkMinY], [linkMaxX, linkMaxY]] = link.l;

  if (
    wordMaxX - fudgeFactor < linkMinX ||
    wordMinX + fudgeFactor > linkMaxX ||
    wordMaxY - fudgeFactor < linkMinY ||
    wordMinY + fudgeFactor > linkMaxY
  ) {
    return false;
  }

  return true;
};

const findLink = (word: string) => {
  const [linkFound] = find(word);

  // We don't want words that don't begin with "http" to be classed as links
  // as this causes false positive link detection for multiline links
  // where the first line ends with a "."
  if (linkFound?.type === "url" && !word.startsWith("http")) {
    return null;
  }

  return linkFound;
};

export const getLinkForWord = (
  word: Word,
  previousWord: Word | null,
  nextWord: Word | null,
  embeddedLinks: Link[],
): string | null => {
  if (embeddedLinks && embeddedLinks.length > 0) {
    const embeddedLink = embeddedLinks.find((link) =>
      linkOverlapsWord(word, link),
    );

    if (embeddedLink) {
      const url = embeddedLink.u;
      return url;
    }
  }

  const wordData = word.d;

  if (nextWord && nextWord.li === word.li + 1) {
    const combinedWord = wordData + nextWord.d;
    const combinedWordLink = findLink(combinedWord);
    if (combinedWordLink) {
      return combinedWordLink.href;
    }
  }

  if (previousWord && previousWord.li === word.li - 1) {
    const combinedWord = previousWord.d + wordData;
    const combinedWordLink = findLink(combinedWord);
    if (combinedWordLink) {
      return combinedWordLink.href;
    }
  }

  const link = findLink(wordData);
  if (link) {
    return link.href;
  }

  return null;
};
