import { ProductFruits } from "react-product-fruits";
import config from "shared/config";
import { UserType } from "shared/helpers/users";
import { Institution, isCatalystTenancy } from "shared/institution";
import { CurrentUserData, isAcademic } from "shared/redux/currentUser";

export type ElevateProductFruitsProps = {
  currentUser: CurrentUserData | undefined;
  institution: Institution | undefined;
};

const ElevateProductFruits = ({
  currentUser,
  institution,
}: ElevateProductFruitsProps) => {
  if (!currentUser || !institution) {
    return null;
  }

  const tenantType = isCatalystTenancy(institution) ? "catalyst" : "elevate";
  const role = isAcademic(currentUser, institution.shortCode)
    ? UserType.Academic
    : UserType.Student;

  const workspaceCode = config.productFruitsWorkspaceId;

  const productFruitsProps = {
    username: currentUser.guid,
    role,
    props: {
      tenant: institution.shortCode,
      tenantType,
    },
  };

  return (
    <ProductFruits
      workspaceCode={workspaceCode}
      language="en"
      user={productFruitsProps}
    />
  );
};

export default ElevateProductFruits;
