import "./loggedOut.scss";

import { ReactComponent as Logo } from "img/logo-colour.svg";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export type LoggedOutProps = {
  inAnIframe?: boolean;
};

const LoggedOut = ({
  inAnIframe = window.self !== window.top,
}: LoggedOutProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const returnTo = searchParams.get("returnTo");

  return (
    <main className="logged-out container-fluid">
      <div className="row">
        <div className="col-10 col-md-4 col-lg-4 offset-1 offset-md-5">
          <Logo />
          <h1 className="visually-hidden">Talis Elevate</h1>
          <h2 className="h1">
            {t("loggedOut.heading", "You've been successfully logged out.")}
          </h2>
          {(!returnTo || inAnIframe) && (
            <h3>
              {t(
                "loggedOut.accessAgain",
                "Need access again? Access via your online learning environment",
              )}
            </h3>
          )}
          {returnTo && !inAnIframe && (
            <div>
              <h3>
                {t(
                  "loggedOut.accessResource",
                  "Want to access this resource again?",
                )}
              </h3>
              <Link to={returnTo}>
                <button className="btn btn-primary" type="button">
                  {t("loggedOut.btn-login", "Login")}
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default LoggedOut;
