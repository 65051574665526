import { connect } from "react-redux";
import { selectIsAcademic } from "shared/redux/currentUser";
import { selectInstitution } from "shared/redux/institution";
import { RootState } from "shared/redux/store";

import Zendesk from "./Zendesk";

const mapStateToProps = (state: RootState) => {
  return {
    institution: selectInstitution(state),
    isAcademic: selectIsAcademic(state),
  };
};

export * from "./Zendesk";

export default connect(mapStateToProps)(Zendesk);
