import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  ForwardedRef,
  ReactElement,
  forwardRef,
  useLayoutEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";

export enum LoaderType {
  FullPage = "fullPage",
  BlockSpinner = "blockSpinner",
  Spinner = "spinner",
  OverlayBlockSpinner = "overlayBlockSpinner",
  FixedSpinner = "fixedSpinner",
}

export enum LoaderPosition {
  Top = "top",
  Center = "center",
}

export type LoaderProps = {
  type?: LoaderType;
  position?: LoaderPosition;
};

const Spinner = forwardRef(
  (
    { type, position }: LoaderProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): ReactElement => {
    const { t } = useTranslation();

    const getLoaderType = (): string => {
      switch (type) {
        case LoaderType.FullPage:
          return "loading-indicator";
        case LoaderType.BlockSpinner:
          return "text-center mt-5";
        case LoaderType.OverlayBlockSpinner:
          return "loading-indicator position-absolute";
        case LoaderType.FixedSpinner:
          return "fixed-loading-indicator position-fixed mt-5 text-body";
        default:
          return "d-inline";
      }
    };

    const getPositionType = (): string => {
      switch (position) {
        case LoaderPosition.Top:
          return "align-items-start pt-5";
        default:
          return "";
      }
    };

    return (
      <div
        ref={ref}
        className={classNames(getLoaderType(), getPositionType())}
        role="status"
      >
        {type === "fullPage" && (
          <div className="cradle">
            <div className="cradle-node">
              <div className="cradle-ball"></div>
            </div>
            <div className="cradle-node">
              <div className="cradle-ball"></div>
            </div>
            <div className="cradle-node">
              <div className="cradle-ball"></div>
            </div>
            <div className="cradle-node">
              <div className="cradle-ball"></div>
            </div>
          </div>
        )}

        {(type === LoaderType.BlockSpinner ||
          type === LoaderType.FixedSpinner ||
          type === LoaderType.OverlayBlockSpinner) && (
          <span className="fa-layers fa-fw fa-6x">
            <FontAwesomeIcon icon={["fas", "square"]} />
            <FontAwesomeIcon
              icon={["fal", "spinner-third"]}
              spin
              inverse
              transform="shrink-6"
            />
          </span>
        )}

        {type === LoaderType.Spinner && (
          <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
        )}

        <span className="visually-hidden">
          {t("global.status-loading", "Loading")}
        </span>
      </div>
    );
  },
);

Spinner.displayName = "Spinner";

const Loader = ({
  type = LoaderType.FullPage,
  position = LoaderPosition.Center,
}: LoaderProps): ReactElement => {
  const spinnerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      type === LoaderType.FixedSpinner &&
      spinnerRef.current &&
      wrapperRef.current
    ) {
      const spinner = spinnerRef.current;
      const spinnerWidth = spinner.clientWidth;

      const wrapper = wrapperRef.current;
      wrapper.style.left = `calc(50% - ${spinnerWidth / 2}px)`;
    }
  }, [type]);

  return (
    <>
      {type === LoaderType.FixedSpinner ? (
        <div className="position-relative">
          <div ref={wrapperRef} className="position-absolute">
            <Spinner ref={spinnerRef} type={type} position={position} />
          </div>
        </div>
      ) : (
        <Spinner ref={spinnerRef} type={type} position={position} />
      )}
    </>
  );
};

export default Loader;
