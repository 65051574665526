import { WordWithIndex } from "../annotations";

export type WordPosition = {
  top: number;
  left: number;
  width: number;
  height: number;
};
export type WordMapEntry = WordWithIndex & {
  position: WordPosition;
  link: string | null;
};
export type WordMap = WordMapEntry[];

export type WordDataByPage = Record<number, WordMap>;
export type WordDataByFile = Record<string, WordDataByPage>;
export type WordDataState = WordDataByFile;

export const initialWordDataState: WordDataState = {};
