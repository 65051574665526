import { configureStore, isPlain } from "@reduxjs/toolkit";
import { baseApi } from "shared/api/baseApi";
import { isJsonApiEntity } from "shared/api/jsonapi-utils";

import { listenerMiddleware } from "./listenerMiddleware";
import { loadReduxState } from "./persistence";
import { wordDataSlice } from "./player/wordData";
import { rootReducer } from "./rootReducer";

const preloadedState = loadReduxState();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [baseApi.reducerPath, wordDataSlice.name],
        isSerializable: (value: unknown) =>
          isPlain(value) || isJsonApiEntity(value),
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(baseApi.middleware),
  preloadedState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
