import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "shared/redux/store";

import type {
  PlayerSelectionsState,
  SelectedSearchResult,
} from "./playerSelectionsState";

const selectPlayerSelections = (state: RootState): PlayerSelectionsState =>
  state.playerSelections;

export const selectShowAnnotations = createSelector(
  [selectPlayerSelections],
  (playerSelections) => playerSelections.showAnnotations,
);

export const selectCurrentAnnotationsFeed = createSelector(
  [selectPlayerSelections],
  (playerSelections) => playerSelections.selectedAnnotationFeed,
);

export const selectSubmittedSearchTerm = createSelector(
  [selectPlayerSelections],
  (playerSelections) => playerSelections.submittedSearchTerm,
);

const selectSelectedSearchResult = createSelector(
  [selectPlayerSelections],
  (playerSelections) => playerSelections.selectedSearchResult,
);

export const createSelectSearchResultForPage = () =>
  createSelector(
    [
      (state: RootState, page: number): number => page,
      selectSelectedSearchResult,
    ],
    (
      page: number,
      selectedSearchResult?: SelectedSearchResult,
    ): SelectedSearchResult | undefined => {
      if (!selectedSearchResult) {
        return undefined;
      }

      return selectedSearchResult.page === page
        ? selectedSearchResult
        : undefined;
    },
  );
