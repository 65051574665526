const formatError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  } else if (typeof error === "string") {
    return Error(error);
  } else if (typeof error === "object" && error !== null) {
    return Error(JSON.stringify(error));
  }

  return Error(String(error));
};

type InstanaContext = Record<string, string | number | boolean>;

export const logError = (error: unknown, context?: InstanaContext): void => {
  const loggedError = formatError(error);

  if (context) {
    console.error(loggedError, context);
  } else {
    console.error(loggedError);
  }

  // Instana does not log the error cause, so
  // we have to format it and push it to the context
  if (loggedError.cause) {
    context = {
      ...context,
      cause: `${loggedError.cause}`,
    };
  }

  if (typeof ineum !== "undefined") {
    if (context) {
      ineum("reportError", loggedError, {
        meta: context,
      });
    } else {
      ineum("reportError", loggedError);
    }
  }
};

export const logComponentError = (
  error: unknown,
  componentStack: string,
): void => {
  if (typeof ineum !== "undefined") {
    const loggedError = formatError(error);
    ineum("reportError", loggedError, { componentStack });
  }
};
