import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "bootstrap";
import { createContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const NotificationContext =
  createContext<NotificationProviderValues | null>(null);

export enum NotificationType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export type NotificationOptions = {
  timeout: number;
  showClose: boolean;
  showLoader: boolean;
};

type NotificationProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export interface NotificationProviderValues {
  showError: (message: string, options?: Partial<NotificationOptions>) => void;
  showWarning: (
    message: string | JSX.Element,
    options?: Partial<NotificationOptions>,
  ) => void;
  showSuccess: (
    message: string,
    options?: Partial<NotificationOptions>,
  ) => void;
  showInfo: (message: string, options?: Partial<NotificationOptions>) => void;
  close: () => void;
}

type NotificationMessage = {
  message: string | JSX.Element;
  messageType: NotificationType;
  showClose: boolean;
  showLoader: boolean;
};

const defaultNotificationOptions: NotificationOptions = {
  timeout: 5000,
  showClose: true,
  showLoader: false,
};

const NotificationProvider = (
  props: NotificationProviderProps,
): JSX.Element => {
  const { t } = useTranslation();

  const [message, setMessage] = useState<NotificationMessage>();
  const hideTimeout = useRef<number>();
  const toastDomRef = useRef<HTMLDivElement>(null);
  const toast = useRef<Toast>();

  const showToast = () => {
    toast.current = new Toast(toastDomRef.current || "", {});
    toast.current.show();
  };

  const hideToast = () => {
    if (!toast.current) {
      return;
    }
    toast.current.hide();
  };

  const providerValue: NotificationProviderValues = useMemo(() => {
    const displayMessage = (
      message: string | JSX.Element,
      messageType: NotificationType,
      options?: Partial<NotificationOptions>,
    ) => {
      const { showClose, showLoader, timeout } = {
        ...defaultNotificationOptions,
        ...options,
      };

      setMessage({
        message,
        messageType,
        showClose,
        showLoader,
      });

      if (toastDomRef.current) {
        toastDomRef.current.setAttribute(
          "class",
          `toast align-items-center fade ${messageTypeToBsClass(messageType)}`,
        );
      }

      showToast();
      clearTimeout(hideTimeout.current);

      if (timeout > 0) {
        hideTimeout.current = window.setTimeout(() => {
          hideToast();
        }, timeout);
      }
    };

    const close = (): void => {
      hideToast();
    };

    return {
      showError: (message: string, options?: Partial<NotificationOptions>) =>
        displayMessage(message, NotificationType.Error, options),
      showWarning: (
        message: string | JSX.Element,
        options?: Partial<NotificationOptions>,
      ) => displayMessage(message, NotificationType.Warning, options),
      showSuccess: (message: string, options?: Partial<NotificationOptions>) =>
        displayMessage(message, NotificationType.Success, options),
      showInfo: (message: string, options?: Partial<NotificationOptions>) =>
        displayMessage(message, NotificationType.Info, options),
      close,
    };
  }, []);

  const messageTypeToBsClass = (messageType: NotificationType): string => {
    switch (messageType) {
      case NotificationType.Error:
        return "toast-danger";
      case NotificationType.Warning:
        return "toast-warning";
      case NotificationType.Success:
        return "toast-success";
      case NotificationType.Info:
        return "toast-info";
      default:
        return "";
    }
  };

  const messageTypeToAriaRole = (): string => {
    if (!message) {
      return "status";
    }
    switch (message.messageType) {
      case NotificationType.Error:
        return "alert";
      default:
        return "status";
    }
  };

  const getToastIcon = (): JSX.Element | null => {
    if (!message) {
      return null;
    }

    if (message && message.showLoader) {
      return (
        <FontAwesomeIcon
          icon={["fal", "spinner-third"]}
          className="fa-spin"
          data-testid="notification-spinner"
        />
      );
    }
    switch (message.messageType) {
      case NotificationType.Error:
        return <FontAwesomeIcon icon={["fal", "times"]} />;
      case NotificationType.Success:
        return <FontAwesomeIcon icon={["fal", "check"]} />;
    }
    return null;
  };

  return (
    <NotificationContext.Provider value={providerValue}>
      {props.children}

      <div className="toast-container position-fixed bottom-0 start-50 translate-middle-x">
        <div
          ref={toastDomRef}
          className="toast align-items-center fade"
          role={messageTypeToAriaRole()}
          aria-atomic="true"
          data-bs-autohide="false"
        >
          <div className="d-flex">
            {getToastIcon()}
            <div className="toast-body">{message?.message}</div>
            {message?.showClose ? (
              <button
                type="button"
                className="btn-close pendo-notification-close"
                data-bs-dismiss="toast"
              >
                {t("global.btn-close", "Close")}
              </button>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
