import { useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { buildRedirectUrl } from "./splashScreenLoginHelper";
import {
  INST_CODE_FOR_SILENT_AUTH_KEY,
  INST_CODE_KEY,
} from "shared/localStorage/keys";

type SplashScreenLoginHandlerProps = {
  splashScreen: React.ComponentType<SplashScreen>;
};

export type SplashScreen = {
  storedInstCode: string | null;
  onSelectInstitution: (instCode: string) => void;
};

const SplashScreenLoginHandler = (
  props: SplashScreenLoginHandlerProps,
): JSX.Element => {
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  const handleSelectInstitution = (instCode: string) => {
    localStorage.setItem(INST_CODE_KEY, instCode);
    localStorage.setItem(INST_CODE_FOR_SILENT_AUTH_KEY, instCode);

    const redirectUri = buildRedirectUrl(
      instCode,
      searchParams.get("redirectUri"),
      "/manager",
    );
    setRedirectTo(redirectUri);
  };

  if (redirectTo !== null) {
    return <Navigate to={redirectTo} />;
  }

  const SplashScreenToRender = props.splashScreen;
  const storedInstCode = localStorage.getItem(INST_CODE_KEY);

  return (
    <SplashScreenToRender
      storedInstCode={storedInstCode}
      onSelectInstitution={handleSelectInstitution}
    />
  );
};

export default SplashScreenLoginHandler;
