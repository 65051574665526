import "react-bootstrap-typeahead/css/Typeahead.css";

import "./catalystSplashScreen.scss";

import { SplashScreen } from "core/splash/SplashScreenLoginHandler";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "shared/Alert";
import { AlertLevel } from "shared/Alert/Alert";
import {
  Institution,
  fetchInstitutionsList,
  isCatalystTenancy,
} from "shared/institution";
import Loader from "shared/Loader";
import { LoaderType } from "shared/Loader/Loader";
import { logError } from "shared/log";
import TimeOfDayGreeting, { TimeOfDay, getTimeOfDay } from "shared/TimeOfDay";

import { ReactComponent as Logo } from "./sage_logo_catalyst.svg";
import SplashScreenTypeahead from "core/splash/SplashScreenTypeahead";

const CatalystSplashScreen = ({
  onSelectInstitution,
  storedInstCode,
}: SplashScreen): JSX.Element => {
  const { t } = useTranslation();

  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(true);
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [selectedInst, setSelectedInst] = useState<Institution[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const loadInstitutions = async () => {
      try {
        const institutions = await fetchInstitutionsList();
        setInstitutions(institutions.filter(isCatalystTenancy));
      } catch (error) {
        logError(error);
      }
      setIsLoadingInstitutions(false);
    };

    loadInstitutions();
  }, []);

  useEffect(() => {
    if (storedInstCode && !isLoadingInstitutions) {
      const matchingInst = institutions.find(
        (i) => i.shortCode === storedInstCode,
      );

      if (matchingInst) {
        setSelectedInst([matchingInst]);
      }
    }
  }, [isLoadingInstitutions, institutions, storedInstCode]);

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (selectedInst.length) {
      const [inst] = selectedInst;
      return onSelectInstitution(inst.shortCode);
    }
    setErrorMessage(
      t(
        "catalystSplashScreen.error-invalidInstitution",
        "Select your institution from the list",
      ),
    );
  };

  const timeOfDay: TimeOfDay = getTimeOfDay();

  if (isLoadingInstitutions) {
    return <Loader type={LoaderType.FullPage} />;
  }

  return (
    <main className="c-catalyst__splash-screen container-fluid">
      <div className="row">
        <div className="col-10 col-md-4 col-lg-4 offset-1 offset-md-5">
          <Logo className="c-catalyst-logo" />
          <h1 className="visually-hidden">Sage Catalyst</h1>
          <h2 className="alpha mt-4">
            <TimeOfDayGreeting timeOfDay={timeOfDay} />
            <div className="alpha mt-2">
              {t(
                "catalystSplashScreen.title-selectInstitution",
                "Search for your institution",
              )}
            </div>
          </h2>

          {errorMessage && (
            <Alert message={errorMessage} level={AlertLevel.Warning} />
          )}

          <form
            className="row row-cols-lg-auto mt-3 g-1 align-items-center"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="col-12 col-lg-8">
              <SplashScreenTypeahead
                institutions={institutions}
                selectedInst={selectedInst}
                setSelectedInst={setSelectedInst}
              />
            </div>
            <div className="col-12 col-lg-1">
              <div className="d-grid">
                <button
                  type="submit"
                  className="btn-lg btn btn-alert pendo-catalystSplashScreen-go"
                >
                  {t("catalystSplashScreen.btn-go", "Go")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CatalystSplashScreen;
