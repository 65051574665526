import { v4 } from "uuid";

export const isNullOrUndefinedOrWhiteSpace = (str?: string | null) => {
  if (str === undefined || str === null) {
    return true;
  }

  return str.trim().length === 0;
};

export const getUniqueId = (): string => v4();
