import { useTranslation } from "react-i18next";

import { TimeOfDay } from "./timeOfDayHelper";

type TimeOfDayGreetingProps = {
  timeOfDay: TimeOfDay;
};

const TimeOfDayGreeting = (props: TimeOfDayGreetingProps): JSX.Element => {
  const { t } = useTranslation();

  const greetings = {
    [TimeOfDay.Morning]: t("timeOfDayGreeting.goodMorning", "Good morning,"),
    [TimeOfDay.Afternoon]: t(
      "timeOfDayGreeting.goodAfternoon",
      "Good afternoon,",
    ),
    [TimeOfDay.Evening]: t("timeOfDayGreeting.goodEvening", "Good evening,"),
  };

  return <div>{greetings[props.timeOfDay]}</div>;
};

export default TimeOfDayGreeting;
