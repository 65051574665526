import axios, { AxiosResponse } from "axios";
import { NotificationSettingsFormData } from "shared/NotificationSettingsModal/notificationTypes";
import { CookiePreference } from "shared/redux/cookiePreferences";
import { generateUrl } from "shared/urlUtils";

export enum UserType {
  Student = "student",
  Academic = "academic",
}

export type UserPlayerSettings = {
  fontSize?: number;
  colourThemeId?: string;
  fontId?: string;
};

export type UserCookiePreferences = {
  acceptCookies: CookiePreference;
  cookieConsentDecisionDate: Date | null;
};

type UserPreferences =
  | UserPlayerSettings
  | NotificationSettingsFormData
  | UserCookiePreferences;

export type UserItem = {
  _id: string;
  guid: string;
  institutionCode: string[];
  lastLogin?: string;
  logins: number;
  profile?: PersonaUserProfile;
  createdVia?: UserCreatedVia;
  preferences?: {
    emailNotificationLevel: number;
    analyticsPushSchedule: {
      dayOfWeek: number;
    };
  } & UserPlayerSettings &
    UserCookiePreferences;
  role: string;
};

export type UserTypeData = {
  guid: string;
  scope: {
    isAdmin: boolean;
    type: UserType;
  };
};

export enum UserCreatedVia {
  TalisInvite = "talisInvite",
  ColleagueInvite = "colleagueInvite",
  Lti = "lti",
}

export interface PersonaUserProfile {
  first_name?: string;
  surname?: string;
  email?: string;
  subject_group?: string;
  discipline?: string;
}

export interface PersonaUser {
  guid: string;
  profile?: PersonaUserProfile;
}

export const updateUserProfile = async (
  userGuid: string,
  userProfile: PersonaUserProfile,
): Promise<UserItem> => {
  const url = generateUrl(`users/${userGuid}/profile`);
  return axios
    .put<PersonaUserProfile, AxiosResponse<UserItem>>(url, userProfile)
    .then((response) => response.data);
};

export const updateLastLogin = async (
  userGuid: string,
  institutionCode: string,
): Promise<UserItem> => {
  const url = generateUrl(`users/${userGuid}/login`);
  return axios
    .post<{ instShortCode: string }, AxiosResponse<UserItem>>(url, {
      instShortCode: institutionCode,
    })
    .then((response) => response.data);
};

export const setCreatedVia = async (
  userGuid: string,
  createdVia: UserCreatedVia,
): Promise<UserItem> => {
  const url = generateUrl(`users/${userGuid}/created-via`);

  return await axios
    .post<{ createdVia: UserCreatedVia }, AxiosResponse<UserItem>>(url, {
      createdVia,
    })
    .then((response) => response.data);
};

export const getUser = async (userGuid: string): Promise<UserItem> => {
  const url = generateUrl(`users/${userGuid}`);
  return axios.get<UserItem>(url).then((response) => response.data);
};

export const updateUserPreferences = async (
  userGuid: string,
  preferences: UserPreferences,
): Promise<UserItem> => {
  const url = generateUrl(`users/${userGuid}/preferences`);

  return axios
    .post<UserPreferences, AxiosResponse<UserItem>>(url, preferences)
    .then((response) => response.data);
};

export const findUser = async (
  emailOrGuid: string,
): Promise<UserItem | null> => {
  if (emailOrGuid.includes("@")) {
    return getPersonaUserByEmail(emailOrGuid).then((user) =>
      user ? fetchUserByUserId(user.guid) : null,
    );
  } else {
    return fetchUserByUserId(emailOrGuid);
  }
};

export const fetchUserByUserId = async (
  userId: string,
): Promise<UserItem | null> => {
  const url = generateUrl(`users/${userId}/hydrate`);

  return axios
    .get<UserItem[]>(url)
    .then((response) => response.data[0])
    .catch((err) => {
      if (err.response?.status === 404) {
        return null;
      }

      throw err;
    });
};

export const getPersonaUserByEmail = async (
  emailAddress: string,
): Promise<PersonaUser | null> => {
  const url = generateUrl("users", { email: emailAddress });
  return axios
    .get<PersonaUser | null>(url)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response?.status === 404) {
        return null;
      }

      throw err;
    });
};

export const findUserByEmail = async (
  emailAddress: string,
  instCode: string,
): Promise<UserItem | null> => {
  const url = generateUrl(`1/${instCode}/users`, {
    "filter[profile.email]": emailAddress,
  });
  return axios
    .get<{ data: UserItem[] } | null>(url)
    .then((response) => (response.data ? response.data.data[0] : null))
    .catch((err) => {
      if (err.response?.status === 404) {
        return null;
      }

      throw err;
    });
};

export const fetchUserType = async (
  userGuid: string,
): Promise<UserTypeData> => {
  const url = generateUrl(`users/${userGuid}/type`);
  return axios.get<UserTypeData>(url).then((response) => response.data);
};
