import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import ErrorPage from "shared/ErrorPage";
import { updateLastLogin } from "shared/helpers/users";
import Loader from "shared/Loader";
import { logError } from "shared/log";
import {
  AuthScope,
  CurrentUserState,
  fetchCurrentUserThunk,
  userHasSufficientScopes,
} from "shared/redux/currentUser";
import { useAppDispatch } from "shared/redux/hooks";
import { RouteParams } from "shared/urlUtils";

import { getAuthProviderIfUnTenanted } from "./authenticatedPageHelper";
import RequestAuthPage from "shared/AuthenticatedPage/RequestAuthPage";

export type AuthenticatedPageProps = {
  currentUserState: CurrentUserState;
  requiredScopes: AuthScope[];
  children: JSX.Element;
};

const AuthenticatedPage = ({
  currentUserState,
  requiredScopes,
  children,
}: AuthenticatedPageProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const { instCode } = useParams() as RouteParams;
  const dispatch = useAppDispatch();
  const [showRequestAuthPage, setShowRequestAuthPage] =
    useState<boolean>(false);
  const [hasFetchedUser, setHasFetchedUser] = useState<boolean>(false);

  const { isLoading, data: currentUser, loadingError } = currentUserState;

  const unauthorizedMessage = t(
    "authenticatedPage.error-unauthorised",
    "You are unauthorised for this page",
  );

  const authProvider =
    instCode || getAuthProviderIfUnTenanted(location.pathname);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (!currentUser && !isLoading && !loadingError && !hasFetchedUser) {
        setHasFetchedUser(true);
        await dispatch(fetchCurrentUserThunk(authProvider || ""));
      }
    };

    fetchCurrentUser();
  }, [
    authProvider,
    currentUser,
    dispatch,
    hasFetchedUser,
    isLoading,
    loadingError,
  ]);

  useEffect(() => {
    if (!hasFetchedUser) {
      return;
    }

    if (!isLoading && !currentUser) {
      setShowRequestAuthPage(true);
    }
  }, [currentUser, hasFetchedUser, instCode, isLoading, location]);

  useEffect(() => {
    if (currentUser) {
      setShowRequestAuthPage(false);
    }
  }, [currentUser]);

  const isAuthorised =
    currentUser &&
    authProvider &&
    userHasSufficientScopes(requiredScopes, currentUser, authProvider);

  useEffect(() => {
    const updateUser = async () => {
      if (isAuthorised) {
        try {
          await updateLastLogin(currentUser.guid, instCode);
        } catch (error) {
          logError(error);
        }
      }
    };

    updateUser();
  }, [currentUser, isAuthorised, instCode]);

  if (!authProvider) {
    return <ErrorPage error={Error(unauthorizedMessage)} />;
  }

  if (showRequestAuthPage || loadingError) {
    return <RequestAuthPage authProvider={authProvider} />;
  }

  if (isLoading || !currentUser) {
    return <Loader />;
  }

  if (isAuthorised) {
    return children;
  }

  return <ErrorPage error={Error(unauthorizedMessage)} />;
};

export default AuthenticatedPage;
