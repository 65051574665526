import { createSlice } from "@reduxjs/toolkit";
import { LoadableFactory } from "shared/loadable";
import { createAppAsyncThunk } from "shared/redux/thunks";

import { fetchInstitution } from "./institutionHelper";
import { initialInstitutionState } from "./institutionState";

export const fetchInstitutionThunk = createAppAsyncThunk(
  "institution/fetchInstitution",
  async (instCode: string) => fetchInstitution(instCode),
);

export const institutionSlice = createSlice({
  name: "institution",
  initialState: initialInstitutionState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInstitutionThunk.pending, (state, action) => {
      return LoadableFactory.loading();
    });
    builder.addCase(fetchInstitutionThunk.fulfilled, (state, action) => {
      return LoadableFactory.loaded(action.payload);
    });
    builder.addCase(fetchInstitutionThunk.rejected, (state, action) => {
      return LoadableFactory.errored(action.error);
    });
  },
});
