import { Modal as BootstrapModal } from "bootstrap";
import * as focusTrap from "focus-trap";
import React, { useEffect, useRef, useState } from "react";

export interface ModalProps<T = Record<string, unknown>> {
  isOpen: boolean;
  onClose: (data?: T) => void;
  onClosed?: () => void;
  returnFocusTo?: string;
  enableFocusTrap?: boolean;
  initialFocus?: HTMLElement | string | false;
}

const Modal = <T,>({
  isOpen,
  onClose,
  onClosed,
  returnFocusTo,
  enableFocusTrap = true,
  initialFocus = false,
  ...props
}: ModalProps<T> & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const [modal, setModal] = useState<BootstrapModal>();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      setModal(new BootstrapModal(modalRef.current));
    }
  }, []);

  useEffect(() => {
    isOpen ? modal?.show() : modal?.hide();
    return () => modal?.hide();
  }, [modal, isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    modalElement?.addEventListener("hide.bs.modal", () => onClose());
    return () => {
      modalElement?.removeEventListener("hide.bs.modal", () => onClose());
    };
  }, [onClose]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (enableFocusTrap && modalElement) {
      const trap = focusTrap.createFocusTrap(modalElement);
      const onShown = () => trap.activate();
      const onHidden = () => {
        trap.deactivate();
        if (onClosed) {
          onClosed();
        }
      };

      modalElement.addEventListener("shown.bs.modal", onShown);
      modalElement.addEventListener("hidden.bs.modal", onHidden);
      return () => {
        modalElement.removeEventListener("shown.bs.modal", onShown);

        if (onClosed) {
          modalElement.removeEventListener("hidden.bs.modal", onHidden);
        }
      };
    }
  }, [enableFocusTrap, onClosed]);

  useEffect(() => {
    const returnFocus = () => {
      if (returnFocusTo) {
        document.getElementById(returnFocusTo)?.focus();
      }
    };
    const modalElement = modalRef.current;
    modalElement?.addEventListener("hidden.bs.modal", returnFocus);
    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", returnFocus);
    };
  }, [returnFocusTo]);

  return (
    <div
      className="modal fade"
      ref={modalRef}
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      {...props}
    ></div>
  );
};

export default Modal;
