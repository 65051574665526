import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PersonaUserProfile } from "shared/helpers/users";
import { LoadableFactory } from "shared/loadable";
import { createAppAsyncThunk } from "shared/redux/thunks";
import { getUniqueId } from "shared/stringUtils";

import { CurrentUserData, silentlyAuthenticateUser } from "./currentUserHelper";
import { CurrentUserState, initialCurrentUserState } from "./currentUserState";
import { LoginResponse } from "@technologyfromsage/persona-padlock";

export const fetchCurrentUserThunk = createAppAsyncThunk(
  "currentUser/fetchCurrentUser",
  async (authProvider: string): Promise<CurrentUserData | undefined> => {
    const loginResponse = await silentlyAuthenticateUser(authProvider);

    if (!loginResponse) {
      return undefined;
    }

    return {
      guid: loginResponse.persona_guid,
      scopes: loginResponse.persona_scopes,
      accessToken: loginResponse.access_token,
      refreshToken: loginResponse.refresh_token,
      profile: loginResponse.persona_profile,
      sessionId: getUniqueId(),
    };
  },
  {
    condition: (_, { getState }) => {
      const {
        currentUser: { isLoading, data: currentUser, loadingError },
      } = getState();
      return !isLoading && !currentUser && !loadingError;
    },
  },
);

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: initialCurrentUserState,
  reducers: {
    setCurrentUserProfile(
      state: CurrentUserState,
      action: PayloadAction<PersonaUserProfile>,
    ) {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          profile: action.payload,
        },
      };
    },
    updateTokens(
      state: CurrentUserState,
      action: PayloadAction<{
        refreshToken?: string;
        accessToken: string;
      }>,
    ) {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
        },
      };
    },
    setCurrentUser(_: CurrentUserState, action: PayloadAction<LoginResponse>) {
      const currentUserData: CurrentUserData = {
        guid: action.payload.persona_guid,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        scopes: action.payload.persona_scopes,
        profile: action.payload.persona_profile,
        sessionId: getUniqueId(),
      };

      return LoadableFactory.loaded(currentUserData);
    },
    updateCurrentUser(
      state: CurrentUserState,
      action: PayloadAction<LoginResponse>,
    ) {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          scopes: action.payload.persona_scopes,
          accessToken: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
          profile: action.payload.persona_profile,
        },
      };
    },
    clearCurrentUser() {
      return initialCurrentUserState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserThunk.pending, (state, action) => {
      return LoadableFactory.loading();
    });
    builder.addCase(fetchCurrentUserThunk.fulfilled, (state, action) => {
      return LoadableFactory.loaded(action.payload);
    });
    builder.addCase(fetchCurrentUserThunk.rejected, (state, action) => {
      return LoadableFactory.errored(action.error);
    });
  },
});

export const {
  setCurrentUserProfile,
  updateTokens,
  setCurrentUser,
  updateCurrentUser,
  clearCurrentUser,
} = currentUserSlice.actions;
