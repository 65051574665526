import { connect } from "react-redux";
import { selectInstitution } from "shared/redux/institution";
import { RootState } from "shared/redux/store";

import { Institution } from "shared/institution";
import GoogleAnalytics from "./GoogleAnalytics";
import {
  CurrentUserData,
  selectCurrentUserState,
} from "shared/redux/currentUser";

type GoogleAnalyticsProps = {
  institution?: Institution;
  currentUser?: CurrentUserData;
};

const mapStateToProps = (state: RootState) => {
  return {
    institution: selectInstitution(state),
    currentUser: selectCurrentUserState(state).data,
  };
};

const UserAwareGoogleAnalytics = ({
  institution,
  currentUser,
}: GoogleAnalyticsProps) => {
  if (currentUser) {
    return (
      <GoogleAnalytics
        institution={institution}
        currentUser={currentUser}
        inIframe={window.top !== window.self}
      />
    );
  }
  return null;
};

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps)(UserAwareGoogleAnalytics);
