import { createSlice } from "@reduxjs/toolkit";
import { LoadableFactory } from "shared/loadable";
import { createAppAsyncThunk } from "shared/redux/thunks";

import { fetchElevateConfig } from "./configHelper";
import { initialConfigState } from "./configState";

export const fetchElevateConfigThunk = createAppAsyncThunk(
  "config/fetchElevateConfig",
  fetchElevateConfig,
);

export const configSlice = createSlice({
  name: "config",
  initialState: initialConfigState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchElevateConfigThunk.pending, (state, action) => {
      return LoadableFactory.loading();
    });
    builder.addCase(fetchElevateConfigThunk.fulfilled, (state, action) => {
      return LoadableFactory.loaded(action.payload);
    });
    builder.addCase(fetchElevateConfigThunk.rejected, (state, action) => {
      return LoadableFactory.errored(action.error);
    });
  },
});
