import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { logError } from "shared/log";
import { isAxiosMockAdaptorMissingMockError } from "shared/testingUtils/missingMocks";

export type ErrorWithMetaData = {
  errors: {
    status: number;
    title: string;
    detail: string;
    meta: Record<string, string>;
  }[];
};

export type BaseQueryError = {
  status: number;
  data: unknown;
};

export const isBaseQueryError = (error: unknown): error is BaseQueryError => {
  return typeof error === "object" && !!error && "status" in error;
};

export const isErrorWithMetaData = (
  data: unknown,
): data is ErrorWithMetaData => {
  return typeof data === "object" && data !== null && "errors" in data;
};

const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, BaseQueryError> =>
  async (requestConfig) => {
    try {
      const result = await axios.request(requestConfig);
      return { data: result.data };
    } catch (error) {
      const axiosError = error as AxiosError;
      if (isAxiosMockAdaptorMissingMockError(axiosError.message)) {
        logError(axiosError);
      }
      return {
        error: {
          status: axiosError.response?.status || 0,
          data: axiosError.response?.data,
        },
      };
    }
  };

export const baseApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ["copyprotections", "modules", "resources", "users"],
  endpoints: () => ({}),
});
