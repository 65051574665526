import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  AnnotationFeedType,
  SelectedSearchResult,
  initialPlayerSelectionsState,
} from "./playerSelectionsState";

export const playerSelectionsSlice = createSlice({
  name: "playerSelections",
  initialState: initialPlayerSelectionsState,
  reducers: {
    setShowAnnotations: (state, action: PayloadAction<boolean>) => {
      state.showAnnotations = action.payload;
    },
    setSelectedAnnotationFeed: (
      state,
      action: PayloadAction<AnnotationFeedType>,
    ) => {
      state.selectedAnnotationFeed = action.payload;
    },
    setSubmittedSearchTerm: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.submittedSearchTerm = action.payload;
    },
    setSelectedSearchResult: (
      state,
      action: PayloadAction<SelectedSearchResult | undefined>,
    ) => {
      state.selectedSearchResult = action.payload;
    },
  },
});

export const {
  setShowAnnotations,
  setSelectedAnnotationFeed,
  setSubmittedSearchTerm,
  setSelectedSearchResult,
} = playerSelectionsSlice.actions;
