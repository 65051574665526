import { baseApi } from "shared/api/baseApi";
import {
  DocumentResourceFile,
  DocumentWords,
} from "shared/api/types/resources/ingested";
import { generateUrl } from "shared/urlUtils";

export const getWordDataUrl = (fileId: string, page: number) =>
  generateUrl(`files/${fileId}/words`, {
    startIndex: page,
    endIndex: page,
    normalizeHeight: true,
  });

const wordDataApi = baseApi.injectEndpoints({
  endpoints: (build) => {
    return {
      getWordData: build.query<
        DocumentWords,
        { file: DocumentResourceFile; page: number }
      >({
        query: ({ file, page }) => {
          const url = getWordDataUrl(file.id, page);
          return { url, method: "GET" };
        },
      }),
    };
  },
});

export const { useGetWordDataQuery } = wordDataApi;

export default wordDataApi;
