import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/redux/store";

import { AnnotationsState } from "./annotationsState";
import { PendingAnnotation } from "./types";

const selectAnnotations = (state: RootState): AnnotationsState =>
  state.annotations;

export const selectPendingAnnotation = createSelector(
  [selectAnnotations],
  (annotations) => annotations.pendingAnnotation,
);

export const pageHasPendingAnnotation = createSelector(
  [(state: RootState, page: number) => page, selectPendingAnnotation],
  (page: number, pendingAnnotation?: PendingAnnotation): boolean => {
    if (!pendingAnnotation) {
      return false;
    }

    return pendingAnnotation.page === page;
  },
);

export const selectSelectedAnnotationId = createSelector(
  [selectAnnotations],
  (annotations) => annotations.selectedAnnotationId,
);
