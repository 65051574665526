import { ReactElement, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { initiateRedirectAuthentication } from "@technologyfromsage/persona-padlock";
import { getPersonaConfig } from "shared/AuthenticatedPage/config";
import ErrorPage from "shared/ErrorPage";
import { useTranslation } from "react-i18next";
import { logError } from "shared/log";

type RequestAuthPageProps = {
  authProvider: string;
};

const RequestAuthPage = ({
  authProvider,
}: RequestAuthPageProps): ReactElement | null => {
  const { t } = useTranslation();
  const location = useLocation();

  const [error, setError] = useState<Error | undefined>();
  const isAuthenticating = useRef(false);

  useEffect(() => {
    if (isAuthenticating.current) {
      return;
    }

    isAuthenticating.current = true;

    const personaConfig = getPersonaConfig(authProvider);
    const redirectUri = `${window.location.origin}/redirect-authentication`;
    const appState = {
      target: `${location.pathname}${location.search}`,
    };

    try {
      initiateRedirectAuthentication(personaConfig, redirectUri, appState);
    } catch (error) {
      logError(error);

      setError(
        Error(
          t(
            "requestAuthPage.initiateRedirectLogin-error",
            "An error occurred while starting the login process.",
          ),
        ),
      );
    }
  }, [authProvider, location.pathname, location.search, t]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  return null;
};

export default RequestAuthPage;
