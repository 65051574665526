import sharedConfig from "shared/config";
import config from "shared/config";
import { PersonaUserProfile } from "shared/helpers/users";
import {
  LoginResponse,
  refreshAccessToken,
  silentlyAuthenticate,
} from "@technologyfromsage/persona-padlock";
import { getPersonaConfig } from "shared/AuthenticatedPage/config";
import { logError } from "shared/log";

export type CurrentUserData = {
  guid: string;
  accessToken: string;
  refreshToken?: string;
  scopes: string[];
  profile?: PersonaUserProfile;
  sessionId: string;
};

export const silentlyAuthenticateUser = async (
  authProvider: string,
): Promise<LoginResponse | undefined> => {
  try {
    const config = getPersonaConfig(authProvider);

    return await silentlyAuthenticate(config);
  } catch (error) {
    logError(error);

    return undefined;
  }
};

export const fetchFreshTokens = async (
  userGuid: string,
  refreshToken?: string,
): Promise<{
  refreshToken: string;
  accessToken: string;
}> => {
  const refreshResponse = await refreshAccessToken({
    personaOrigin: config.oauthOrigin,
    refreshToken: refreshToken || "",
    userGuid: userGuid,
  });

  return {
    refreshToken: refreshResponse.refresh_token,
    accessToken: refreshResponse.access_token,
  };
};

export enum AuthScope {
  Read = "READ",
  Write = "WRITE",
  Support = "SUPPORT",
  Member = "MEMBER",
  SuperUser = "ADMIN",
}

const generateScopeFromType = (scope: AuthScope, instCode: string): string => {
  switch (scope) {
    case AuthScope.Support:
      return "elevate:su";
    case AuthScope.SuperUser:
      return "su";
    case AuthScope.Read:
      return `elevate:read@${instCode}`;
    case AuthScope.Write:
      return `elevate:write@${instCode}`;
    case AuthScope.Member:
      return `member@${instCode}`;
  }
};

export const userHasRequiredScopes = (
  requiredScopes: AuthScope[],
  currentUser: CurrentUserData,
  instCode: string,
): boolean =>
  requiredScopes
    .map((scope) => generateScopeFromType(scope, instCode))
    .every((scope) => currentUser.scopes.includes(scope));

export const userHasSufficientScopes = (
  requiredScopes: AuthScope[],
  currentUser: CurrentUserData,
  instCode: string,
): boolean =>
  isAdmin(currentUser) ||
  userHasRequiredScopes(requiredScopes, currentUser, instCode);

export const isAdmin = (currentUser: CurrentUserData): boolean =>
  currentUser.scopes.includes(
    generateScopeFromType(AuthScope.Support, sharedConfig.adminAuthProvider),
  ) ||
  currentUser.scopes.includes(
    generateScopeFromType(AuthScope.SuperUser, sharedConfig.adminAuthProvider),
  );

export const isAcademic = (
  currentUser: CurrentUserData,
  instCode: string,
): boolean => userHasSufficientScopes([AuthScope.Write], currentUser, instCode);

export const isStudent = (
  currentUser: CurrentUserData,
  instCode: string,
): boolean => !isAcademic(currentUser, instCode) && !isAdmin(currentUser);

export const isSupportMode = (
  currentUser: CurrentUserData,
  instCode: string,
): boolean =>
  currentUser.scopes.includes(
    generateScopeFromType(AuthScope.Support, sharedConfig.adminAuthProvider),
  ) && !userHasRequiredScopes([AuthScope.Member], currentUser, instCode);

export const getInstCodeFromScopes = (scopes: Array<string>): string | null => {
  const filterOutDevolvedConstraints = (scope: string) => !scope.includes(":");

  const memberScope = scopes
    .filter((scope) => scope.includes("member@"))
    .find(filterOutDevolvedConstraints);

  if (memberScope) {
    return memberScope.split("@")[1];
  }

  return null;
};
