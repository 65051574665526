import axios from "axios";
import config from "shared/config";

import { Institution, TenancyType } from "./types";

export const isElevateTenancy = (institution: Institution): boolean => {
  return institution.tenancyTypes[TenancyType.Elevate] || false;
};

export const isCatalystTenancy = (institution: Institution): boolean => {
  return institution.tenancyTypes[TenancyType.Catalyst] || false;
};

export const fetchInstitutionsList = async (): Promise<Institution[]> => {
  const url = new URL("institutions", config.elevateUrl);
  return axios
    .get<Institution[]>(url.toString())
    .then((response) => sortInstitutions(response.data));
};

export const sortInstitutions = (institutions: Institution[]): Institution[] =>
  institutions.sort((a: Institution, b: Institution) => {
    return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
  });
