import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { startAppListening } from "shared/redux/listenerMiddleware";
import { saveReduxState } from "shared/redux/persistence";

import {
  ReadPosition,
  initialPlayerReadPositionsState,
} from "./playerReadPositionState";

export const playerReadPositionSlice = createSlice({
  name: "playerReadPosition",
  initialState: initialPlayerReadPositionsState,
  reducers: {
    setResourceReadPosition: (
      state,
      action: PayloadAction<{ resourceId: string; readPosition: ReadPosition }>,
    ) => {
      state.resourceReadPositions[action.payload.resourceId] =
        action.payload.readPosition;
    },
  },
});

export const { setResourceReadPosition } = playerReadPositionSlice.actions;

startAppListening({
  actionCreator: setResourceReadPosition,
  effect: (action, listenerApi) => {
    const { playerReadPositions } = listenerApi.getState();
    saveReduxState("playerReadPositions", playerReadPositions);
  },
});
