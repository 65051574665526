import { Trans, useTranslation } from "react-i18next";

export type ErrorPageProps = {
  error?: Error;
  additionalMessage?: string;
  showFurtherSupport?: boolean;
};

const ErrorPage = ({
  error,
  additionalMessage,
  showFurtherSupport = true,
}: ErrorPageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="t-error">
      <main className="c-error-page__container">
        <h1 className="c-error-page__header">
          {t("errorPage.heading", "Error!")}
        </h1>
        <h2 className="c-error-page__subheader">
          {t("errorPage.subheading", "Something has gone wrong")}
        </h2>

        <hr />

        <section className="c-error-page__context">
          {error && (
            <dl className="o-dl--inline list-unstyled d-inline-block">
              <dt>{error.name}:</dt>{" "}
              <dd>
                {error.message}
                {additionalMessage && (
                  <p className="mt-2">{additionalMessage}</p>
                )}
              </dd>
            </dl>
          )}
          {showFurtherSupport && (
            <>
              <p>
                <strong>
                  {t(
                    "errorPage.help-needFurtherSupport",
                    "Need further support?",
                  )}
                </strong>
              </p>
              <p>
                <Trans i18nKey="errorPage.help-raiseSupportTicket">
                  You can{" "}
                  <a
                    href="mailto:support@talis.com"
                    className="pendo-errorPage-raiseSupportTicket"
                  >
                    raise a support ticket
                  </a>{" "}
                  and we will be in touch
                </Trans>
              </p>
            </>
          )}
        </section>
      </main>
    </div>
  );
};

export default ErrorPage;
