export type ReadPosition = {
  page: number;
  epubAnchor: string | null;
};

export type ResourceReadPositions = Record<string, ReadPosition>;

export type PlayerReadPositionState = {
  resourceReadPositions: ResourceReadPositions;
};

export const initialPlayerReadPositionsState: PlayerReadPositionState = {
  resourceReadPositions: {},
};
