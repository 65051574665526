type AppConfig = {
  adminAuthProvider: string;
  analyticsApplicationId: string;
  babelWebSocketUrl: string;
  catalystUrl: string;
  depotUrl: string;
  elevateUrl: string;
  googleAnalyticsPropertyId: string;
  hostUrl: string;
  isProduction: boolean;
  nodeEnv?: string;
  oauthAppId: string;
  oauthOrigin: string;
  ltiUrl: string;
  ltiMicroserviceUrl: string;
  clickjackingProtectionDisabled: boolean;
  timeInPlayerInterval: number;
  productFruitsWorkspaceId: string;
};

enum Environment {
  Local = "local",
  Production = "production",
  Preview = "preview",
  Ondemand = "ondemand",
}

const getEnvironment = (hostUrl: string): Environment => {
  if (hostUrl === "https://elevate.talis.com/") {
    return Environment.Production;
  }

  if (hostUrl.includes("preview-otis")) {
    return Environment.Preview;
  }

  if (hostUrl.includes("ondemand-otis")) {
    return Environment.Ondemand;
  }

  return Environment.Local;
};

const timeInPlayerInterval = 10000;

const getConfig = (): AppConfig => {
  const hostUrl = document.location.origin + "/";
  const environment = getEnvironment(hostUrl);

  switch (environment) {
    case Environment.Local:
      return {
        adminAuthProvider: "tfsadminlocal",
        analyticsApplicationId: "development.talis_com.web",
        babelWebSocketUrl: "https://babel-ws.talis.local",
        catalystUrl: "https://catalyst.talis.local:3005/",
        depotUrl: "https://depot.talis.local/",
        elevateUrl: "https://elevate.talis.local/",
        googleAnalyticsPropertyId: "G-00000000",
        hostUrl: "https://otis.talis.local:3000/",
        isProduction: false,
        nodeEnv: "development",
        oauthAppId: "otis-local-app",
        oauthOrigin: "https://persona.talis.local",
        ltiUrl: "https://elevate.talis.local/",
        ltiMicroserviceUrl: "https://staging-lti-admin.talis.io/",
        clickjackingProtectionDisabled:
          process.env.CI === "true" || process.env.CYPRESS === "true",
        timeInPlayerInterval,
        productFruitsWorkspaceId: "ctGHXsG6JkLwb5v7",
      };

    case Environment.Production:
      return {
        adminAuthProvider: "tfsadmin",
        analyticsApplicationId: "production.talis_com.web",
        babelWebSocketUrl: "https://ws-feeds.talis.com",
        catalystUrl: "https://catalyst.talis.com/",
        depotUrl: "https://files.talis.com/",
        elevateUrl: "https://app.talis.com/",
        googleAnalyticsPropertyId: "G-P0NXQ735WM",
        hostUrl,
        isProduction: true,
        nodeEnv: process.env.NODE_ENV,
        oauthAppId: "otis-prod-app",
        oauthOrigin: "https://users.talis.com",
        ltiUrl: "https://app.talis.com/",
        ltiMicroserviceUrl: "https://lti-admin.talis.com/",
        clickjackingProtectionDisabled: false,
        timeInPlayerInterval,
        productFruitsWorkspaceId: "rqpJQiCADhKZu7Ws",
      };
  }

  const elevateUrl = hostUrl.replace("otis", "elevate");
  const [oauthAppId] = new URL(hostUrl).host.split(".");

  switch (environment) {
    case Environment.Preview:
      return {
        adminAuthProvider: "tfsadmin",
        analyticsApplicationId: "preview.talis_com.web",
        babelWebSocketUrl: "https://ws-feeds.talis.com",
        catalystUrl: "https://catalyst.talis.com/",
        depotUrl: "https://files.talis.com/",
        elevateUrl,
        googleAnalyticsPropertyId: "",
        hostUrl,
        isProduction: true,
        nodeEnv: process.env.NODE_ENV,
        oauthAppId,
        oauthOrigin: "https://users.talis.com",
        ltiUrl: elevateUrl,
        ltiMicroserviceUrl: "https://lti-admin.talis.com/",
        clickjackingProtectionDisabled: false,
        timeInPlayerInterval,
        productFruitsWorkspaceId: "ctGHXsG6JkLwb5v7",
      };

    case Environment.Ondemand:
      return {
        adminAuthProvider: "tfsadminstaging",
        analyticsApplicationId: "staging.talis_com.web",
        babelWebSocketUrl: "https://staging-ws-feeds.talis.com",
        catalystUrl: "https://staging-catalyst.talis.io/",
        depotUrl: "https://staging-files.talis.io/",
        elevateUrl,
        googleAnalyticsPropertyId: "",
        hostUrl,
        isProduction: true,
        nodeEnv: process.env.NODE_ENV,
        oauthAppId,
        oauthOrigin: "https://staging-users.talis.com",
        ltiUrl: elevateUrl,
        ltiMicroserviceUrl: "https://staging-lti-admin.talis.io/",
        clickjackingProtectionDisabled: false,
        timeInPlayerInterval,
        productFruitsWorkspaceId: "ctGHXsG6JkLwb5v7",
      };
  }
};

const config = getConfig();

export default config;
