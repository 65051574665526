import { NotificationLevel } from "shared/NotificationSettingsModal/notificationTypes";

import {
  AnalyticsMode,
  HierarchyTypeaheadSource,
  LtiModuleCodeFallback,
  NewInstitution,
  SendAnalyticEmails,
  TenancyType,
} from "./types";

export const DEFAULT_ROLES_FOR_LTI = [
  "urn:lti:instrole:ims/lis/Administrator",
  "urn:lti:role:ims/lis/TeachingAssistant",
  "urn:lti:instrole:ims/lis/Instructor",
  "urn:lti:role:ims/lis/ContentDeveloper",
  "urn:lti:role:ims/lis/Instructor",
  "Instructor",
].join();

export const DEFAULT_ROLES_FOR_LTI_DEEP_LINKING = [
  "http://purl.imsglobal.org/vocab/lis/v2/institution/person#Administrator",
  "http://purl.imsglobal.org/vocab/lis/v2/institution/person#Instructor",
  "http://purl.imsglobal.org/vocab/lis/v2/institution/person#Faculty",
  "http://purl.imsglobal.org/vocab/lis/v2/institution/person#Staff",
  "http://purl.imsglobal.org/vocab/lis/v2/membership#Administrator",
  "http://purl.imsglobal.org/vocab/lis/v2/membership#ContentDeveloper",
  "http://purl.imsglobal.org/vocab/lis/v2/membership#Instructor",
  "http://purl.imsglobal.org/vocab/lis/v2/membership#Mentor",
  "Administrator",
  "ContentDeveloper",
  "Instructor",
  "Mentor",
].join();

export const blankInstitutionsState: NewInstitution = {
  // Main
  name: "",
  shortCode: "",
  tenancyTypes: { [TenancyType.Elevate]: true },
  createdBy: "",
  inactive: false,
  autoProfileEnabled: false,
  // Defaults
  defaultNotificationLevel: NotificationLevel.RepliesAndMentions,
  hierarchyTypeaheadSource: HierarchyTypeaheadSource.Off,
  // Feature flags
  features: {},
  // Analytics
  analyticsMode: AnalyticsMode.Off,
  sendAnalyticEmails: SendAnalyticEmails.On,
  analyticEmailsBccAddress: "",
  // LTI
  enableSaveEmbedDropdown: false,
  trustLTIProfileDetails: false,
  rolesForLtiCiTool: DEFAULT_ROLES_FOR_LTI,
  rolesForLtiDeepLinking: DEFAULT_ROLES_FOR_LTI_DEEP_LINKING,
  ltiModuleCodeFallbackPreference: LtiModuleCodeFallback.ContextLabel,
  // Security
  allowedEmailDomains: "",
  // Catalyst
  a2rAccountId: "",
  catalystPackages: [],
  textbooksFileName: "",
};
