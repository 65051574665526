import { matchPath } from "react-router-dom";

const routesToExclude = [
  "admin/*",
  ":instCode/modules/:moduleId/playlist",
  ":instCode/modules/:moduleId/resources/:resourceId/preview",
  ":instCode/player/modules/:moduleId/resources/:resourceId",
  ":instCode/invite-redemption/:inviteId",
  "/iframe.html",
];

const shouldHideZendesk = (location: string) => {
  return routesToExclude.some((route) => matchPath(route, location));
};

export default shouldHideZendesk;
