import ReactPlayer from "react-player/youtube";
import Modal, { ModalProps } from "shared/Modal";

const SplashScreenVideo = (props: ModalProps): JSX.Element | null => {
  const { isOpen, onClose, returnFocusTo } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} returnFocusTo={returnFocusTo}>
      <div className="modal-dialog mx-auto mw-100 w-75 h-75">
        <div className="modal-content w-100 h-100 border-0">
          <div className="modal-body w-100 h-100 p-0">
            <ReactPlayer
              controls={true}
              height="100%"
              width="100%"
              url="https://www.youtube.com/watch?v=Hxj1NseOjAY"
              tabIndex={0}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SplashScreenVideo;
