import { SetStateAction } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  FilterByCallback,
  Option,
} from "react-bootstrap-typeahead/types/types";
import { useTranslation } from "react-i18next";
import { Institution } from "shared/institution";

export type SplashScreenTypeaheadProps = {
  institutions: Institution[];
  selectedInst: Institution[];
  setSelectedInst: (value: SetStateAction<Institution[]>) => void;
};

const SplashScreenTypeahead = ({
  institutions,
  selectedInst,
  setSelectedInst,
}: SplashScreenTypeaheadProps) => {
  const { t } = useTranslation();

  const filterBy: FilterByCallback = (option, typeaheadProps) => {
    const instName = (option as Institution).name.toLowerCase();
    const instCode = (option as Institution).shortCode.toLowerCase();
    const search = typeaheadProps.text.toLowerCase().trim();

    return instName.indexOf(search) > -1 || instCode.indexOf(search) > -1;
  };

  return (
    <Typeahead
      id="institution-name"
      options={institutions}
      size="lg"
      minLength={2}
      selected={selectedInst}
      onChange={(selected: Option[]) =>
        setSelectedInst(selected as Institution[])
      }
      labelKey="name"
      highlightOnlyResult
      placeholder={t(
        "splashScreenTypeahead.placeholder-institution",
        "Institution name",
      )}
      emptyLabel={t("splashScreenTypeahead.noMatchesFound", "No matches found")}
      filterBy={filterBy}
    />
  );
};

export default SplashScreenTypeahead;
