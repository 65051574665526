export enum NotificationLevel {
  Nothing,
  RepliesAndMentions,
  Everything,
}

export type NotificationSettingsFormData = {
  analyticsPushSchedule: { dayOfWeek: number };
  emailNotificationLevel: NotificationLevel;
};
