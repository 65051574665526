import { createSelector } from "@reduxjs/toolkit";
import {
  FeatureFlag,
  HierarchyTypeaheadSource,
  Institution,
  TenancyType,
} from "shared/institution";
import { RootState } from "shared/redux/store";

import { InstitutionState } from "./institutionState";

export const selectInstitutionState = (state: RootState): InstitutionState =>
  state.institution;

export const selectInstitution = createSelector(
  [selectInstitutionState],
  (institutionState) => institutionState.data,
);

export const selectInstitutionId = createSelector(
  [selectInstitution],
  (institution?: Institution): string => {
    if (!institution) {
      throw new Error("No institution found in state");
    }

    return institution._id;
  },
);

export const selectInstitutionCode = createSelector(
  [selectInstitution],
  (institution?: Institution): string => {
    if (!institution) {
      throw new Error("No institution found in state");
    }

    return institution.shortCode;
  },
);

export const selectFeatureFlag = createSelector(
  [selectInstitution, (state, featureFlag: FeatureFlag) => featureFlag],
  (institution, featureFlag: FeatureFlag): boolean => {
    if (!institution) {
      throw new Error("No institution found in state");
    }

    return (institution.features && institution.features[featureFlag]) || false;
  },
);

export const selectIsCatalystTenancy = createSelector(
  [selectInstitution],
  (institution): boolean => {
    if (!institution) {
      throw new Error("No institution found in state");
    }
    return (
      (institution.tenancyTypes &&
        institution.tenancyTypes[TenancyType.Catalyst]) ||
      false
    );
  },
);

export const selectAllowedEmailDomains = createSelector(
  [selectInstitution],
  (institution?: Institution): string[] => {
    return (
      institution?.allowedEmailDomains
        ?.split("\n")
        .filter((domain) => !!domain) || []
    );
  },
);

export const selectEnableAcademicInvites = createSelector(
  [selectAllowedEmailDomains],
  (allowedEmailDomains: string[]): boolean => allowedEmailDomains.length > 0,
);

export const selectAutoProfileEnabled = createSelector(
  [selectInstitution],
  (institution?: Institution): boolean =>
    institution?.autoProfileEnabled || false,
);

export const selectHierarchyTypeaheadEnabled = createSelector(
  [selectInstitution],
  (institution?: Institution): boolean =>
    institution?.hierarchyTypeaheadSource === HierarchyTypeaheadSource.Tarl ||
    false,
);
