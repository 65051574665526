import { PersonaConfig } from "@technologyfromsage/persona-padlock";
import sharedConfig from "../config";
import config from "shared/config/config";

const fiveMinutes = 5 * 60 * 1000;

const basePersonaConfig: PersonaConfig = {
  personaOrigin: sharedConfig.oauthOrigin,
  // This looks like madness but it's correct. The OAuth app ID is the identifier for the Persona app
  // which we provide as the client ID to satisfy the OAuth flow.
  clientId: config.oauthAppId,
  provider: "",
  timeoutMilliseconds: fiveMinutes,
};

export const getPersonaConfig = (provider: string): PersonaConfig => {
  return {
    ...basePersonaConfig,
    provider,
  };
};
