import {
  CopyrightClearanceResourceFile,
  YoutubeResourceFile,
} from "./external";
import {
  AudioResourceFile,
  DocumentResourceFile,
  EPubResourceFile,
  NewIngestedResourceFile,
  VideoResourceFile,
} from "./ingested";

export enum ResourceType {
  IngestedDocument = "IngestedDocument",
  IngestedVideo = "IngestedVideo",
  IngestedAudio = "IngestedAudio",
  IngestedImage = "IngestedImage",
  YouTubeVideo = "YouTubeVideo",
  CopyrightClearanceDocument = "CopyrightClearanceDocument",
  Textbook = "Textbook",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EPubTextbook = "EPubTextbook",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IngestedEPub = "IngestedEPub",
  Resource = "Resource",
}

export type IngestedResourceFileType =
  | EPubResourceFile
  | DocumentResourceFile
  | VideoResourceFile
  | AudioResourceFile;

export type ExternalResourceFileType =
  | CopyrightClearanceResourceFile
  | YoutubeResourceFile;

export type ResourceFile =
  | IngestedResourceFileType
  | NewIngestedResourceFile
  | ExternalResourceFileType;
