import { useCallback, useState } from "react";
import { useAppSelector, useAppDispatch } from "shared/redux/hooks";
import { selectUserGuid } from "shared/redux/currentUser";
import { getUser, updateUserPreferences } from "shared/helpers/users";
import { logError } from "shared/log";
import {
  CookiePreference,
  selectCookiePreference,
  setCookiePreference,
} from "shared/redux/cookiePreferences";

const useCookiePreferences = () => {
  const [isFetchingCookiePreference, setIsFetchingCookiePreference] =
    useState(false);

  const userGuid = useAppSelector(selectUserGuid);
  const { acceptCookies } = useAppSelector(selectCookiePreference);

  const dispatch = useAppDispatch();

  const fetchCookies = useCallback(async () => {
    if (
      acceptCookies !== CookiePreference.Unknown ||
      isFetchingCookiePreference ||
      !userGuid
    ) {
      return;
    }

    try {
      setIsFetchingCookiePreference(true);
      const userData = await getUser(userGuid);
      dispatch(
        setCookiePreference({
          acceptCookies:
            userData.preferences?.acceptCookies || CookiePreference.NotSet,
          cookieConsentDecisionDate:
            userData.preferences?.cookieConsentDecisionDate || null,
        }),
      );
      setIsFetchingCookiePreference(false);
    } catch (e) {
      logError(e);
    }
  }, [
    acceptCookies,
    dispatch,
    userGuid,
    isFetchingCookiePreference,
    setIsFetchingCookiePreference,
  ]);

  const saveCookiePreference = useCallback(
    async (guid: string, cookiePreference: CookiePreference) => {
      const cookiePreferenceState = {
        acceptCookies: cookiePreference,
        cookieConsentDecisionDate: new Date(),
      };

      dispatch(setCookiePreference(cookiePreferenceState));
      await updateUserPreferences(guid, cookiePreferenceState);
    },
    [dispatch],
  );

  return {
    saveCookiePreference,
    fetchCookies,
    cookiePreference: acceptCookies,
  };
};

export default useCookiePreferences;
