import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getColourTheme } from "shared/userSettings/colourPreferencesHelper";
import { getFont } from "shared/userSettings/fontPreferencesHelper";

import { initialPlayerSettingsState } from "./playerSettingsState";

export const playerSettingsSlice = createSlice({
  name: "playerSettings",
  initialState: initialPlayerSettingsState,
  reducers: {
    setFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    resetFontSize: (state) => {
      state.fontSize = initialPlayerSettingsState.fontSize;
    },
    setColourTheme: (state, action: PayloadAction<string>) => {
      const colourTheme = getColourTheme(action.payload);
      if (colourTheme) {
        state.colourTheme = colourTheme;
      }
    },
    setFontPreference: (state, action: PayloadAction<string>) => {
      const fontPreference = getFont(action.payload);
      if (fontPreference) {
        state.fontPreference = fontPreference;
      }
    },
  },
});

export const { setFontSize, resetFontSize, setColourTheme, setFontPreference } =
  playerSettingsSlice.actions;
