import { connect } from "react-redux";
import { selectConfigIsLoading } from "shared/redux/config";
import { RootState } from "shared/redux/store";

import AppRouter from "./AppRouter";

const mapStateToProps = (state: RootState) => ({
  isConfigLoading: selectConfigIsLoading(state),
});

export default connect(mapStateToProps)(AppRouter);
