import {
  ColourTheme,
  defaultColourTheme,
} from "shared/userSettings/colourPreferencesHelper";
import {
  FontPreference,
  defaultFontPreference,
} from "shared/userSettings/fontPreferencesHelper";

export type PlayerSettingsState = {
  colourTheme: ColourTheme;
  fontSize: number;
  fontPreference: FontPreference;
};

export const PLAYER_DEFAULT_FONT_SIZE = 18;

export const initialPlayerSettingsState: PlayerSettingsState = {
  colourTheme: defaultColourTheme,
  fontSize: PLAYER_DEFAULT_FONT_SIZE,
  fontPreference: defaultFontPreference,
};
