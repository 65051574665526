import { PendingAnnotation } from "./types";

export type AnnotationsState = {
  pendingAnnotation?: PendingAnnotation;
  selectedAnnotationId?: string;
};

export const initialAnnotationsState: AnnotationsState = {
  pendingAnnotation: undefined,
  selectedAnnotationId: undefined,
};
