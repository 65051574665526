export enum TimeOfDay {
  Morning,
  Afternoon,
  Evening,
}

export const getTimeOfDay = (date?: Date): TimeOfDay => {
  const dateToUse = date || new Date();
  const hour = dateToUse.getHours();

  if (hour < 12) {
    return TimeOfDay.Morning;
  }

  if (hour < 18) {
    return TimeOfDay.Afternoon;
  }

  return TimeOfDay.Evening;
};
