import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { initialAnnotationsState } from "./annotationsState";
import { PendingAnnotation } from "./types";

export const annotationsSlice = createSlice({
  name: "annotations",
  initialState: initialAnnotationsState,
  reducers: {
    setPendingAnnotation: (state, action: PayloadAction<PendingAnnotation>) => {
      state.pendingAnnotation = action.payload;
    },
    resetPendingAnnotation: (state) => {
      state.pendingAnnotation = undefined;
    },
    setSelectedAnnotationId: (state, action: PayloadAction<string>) => {
      state.selectedAnnotationId = action.payload;
    },
    resetSelectedAnnotationId: (state) => {
      state.selectedAnnotationId = undefined;
    },
  },
});

export const {
  setPendingAnnotation,
  resetPendingAnnotation,
  setSelectedAnnotationId,
  resetSelectedAnnotationId,
} = annotationsSlice.actions;
