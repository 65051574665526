import "react-bootstrap-typeahead/css/Typeahead.css";

import "./elevateSplashScreen.scss";

import classNames from "classnames";
import { SplashScreen } from "core/splash/SplashScreenLoginHandler";
import { ReactComponent as Logo } from "img/logo-colour.svg";
import { FormEvent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Alert from "shared/Alert";
import { AlertLevel } from "shared/Alert/Alert";
import { Institution, fetchInstitutionsList } from "shared/institution";
import Loader from "shared/Loader";
import { LoaderType } from "shared/Loader/Loader";
import { logError } from "shared/log";
import TimeOfDayGreeting, { TimeOfDay, getTimeOfDay } from "shared/TimeOfDay";

import SplashScreenVideo from "./SplashScreenVideo";
import SplashScreenTypeahead from "core/splash/SplashScreenTypeahead";

const ElevateSplashScreen = ({
  onSelectInstitution,
  storedInstCode,
}: SplashScreen): JSX.Element => {
  const { t } = useTranslation();

  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(true);
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [selectedInst, setSelectedInst] = useState<Institution[]>([]);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const loadInstitutions = async () => {
      try {
        const institutions = await fetchInstitutionsList();
        setInstitutions(
          institutions.filter((institution) => institution.inactive !== true),
        );
      } catch (error) {
        logError(error);
      }
      setIsLoadingInstitutions(false);
    };

    loadInstitutions();
  }, []);

  useEffect(() => {
    if (storedInstCode && !isLoadingInstitutions) {
      const matchingInst = institutions.find(
        (i) => i.shortCode === storedInstCode,
      );

      if (matchingInst) {
        setSelectedInst([matchingInst]);
      }
    }
  }, [isLoadingInstitutions, institutions, storedInstCode]);

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    if (selectedInst.length) {
      const [inst] = selectedInst;
      return onSelectInstitution(inst.shortCode);
    }
    setErrorMessage(
      t(
        "splashScreen.error-invalidInstitution",
        "Select your institution from the list",
      ),
    );
  };

  const timeOfDay: TimeOfDay = getTimeOfDay();

  const containerClasses = {
    [TimeOfDay.Morning]: "c-splash-screen--morning",
    [TimeOfDay.Afternoon]: "c-splash-screen--afternoon",
    [TimeOfDay.Evening]: "c-splash-screen--evening",
  };

  if (isLoadingInstitutions) {
    return <Loader type={LoaderType.FullPage} />;
  }

  return (
    <main
      className={classNames(
        "splash-screen",
        "container-fluid",
        containerClasses[timeOfDay],
      )}
    >
      <div className="row">
        <div className="col-10 col-md-4 col-lg-4 offset-1 offset-md-5">
          <Logo />
          <h1 className="mt-4">
            <TimeOfDayGreeting timeOfDay={timeOfDay} />
            <div className="mt-2">
              {t(
                "splashScreen.title-selectInstitution",
                "Search for your institution",
              )}
            </div>
          </h1>

          {errorMessage && (
            <Alert message={errorMessage} level={AlertLevel.Warning} />
          )}

          <form
            className="row row-cols-lg-auto mt-3 g-1 align-items-center"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="col-12 col-lg-8">
              <SplashScreenTypeahead
                institutions={institutions}
                selectedInst={selectedInst}
                setSelectedInst={setSelectedInst}
              />
            </div>
            <div className="col-12 col-lg-1">
              <div className="d-grid">
                <button
                  type="submit"
                  className="btn-lg btn btn-primary pendo-splashScreen-go"
                >
                  {t("splashScreen.btn-go", "Go")}
                </button>
              </div>
            </div>
          </form>
          <div>
            <p className="c-splash-screen__video-cta__text fs-4 fw-light fst-italic">
              <Trans i18nKey="splashScreen.message-cta">
                Build resource collaboration, engagement, and insight into the
                heart of your learning and teaching practice.
              </Trans>
            </p>

            <button
              id="btnFindOutMore"
              type="button"
              className="btn btn-lg btn-default pendo-splashScreen-findOutMore"
              onClick={() => setShowVideo(true)}
            >
              {t("splashScreen.btn-findOutMore", "Find out more")}
            </button>
          </div>
        </div>
      </div>
      <SplashScreenVideo
        isOpen={showVideo}
        returnFocusTo="btnFindOutMore"
        onClose={() => setShowVideo(false)}
      />
    </main>
  );
};

export default ElevateSplashScreen;
