import { Navigate } from "react-router-dom";

import { INST_CODE_FOR_SILENT_AUTH_KEY } from "shared/localStorage/keys";

const Home = (): JSX.Element => {
  let redirectTo = "/splash";
  const storedInstCode = localStorage.getItem(INST_CODE_FOR_SILENT_AUTH_KEY);

  if (storedInstCode) {
    redirectTo = `/${storedInstCode}/manager/modules`;
  }

  return <Navigate to={redirectTo} replace={true} />;
};

export default Home;
