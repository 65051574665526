import {
  JsonapiEntity,
  MetaObject,
  PagedTopLevelData,
  PaginationLinksObject,
  ResourceIdentifier,
  ResourceIdentifierConstructor,
  TopLevelDatum,
  deserialiseMany,
  deserialiseOne,
  isEntityConstructorRegistered,
  toJsonApi,
} from "jsonapi-transformers";

export interface PageOf<T extends JsonapiEntity<T>> {
  data: T[];
  links?: PaginationLinksObject;
  meta?: MetaObject;
}

export const convertToJsonapiResponseDatum = deserialiseOne;

export const convertToJsonapiResponseData = deserialiseMany;

export function convertToJsonApiPagedResponseData<T extends JsonapiEntity<T>>(
  pagedTopLevelData: PagedTopLevelData,
): PageOf<T> {
  const { links, meta } = pagedTopLevelData || { links: {}, meta: {} };
  return {
    data: convertToJsonapiResponseData<T>(pagedTopLevelData),
    links,
    meta,
  };
}

export function convertEntityToJsonapiBody(
  entity: ResourceIdentifier,
): TopLevelDatum {
  return { data: toJsonApi(entity) };
}

export function convertEntitiesToJsonapiBody(
  entities: ResourceIdentifier[],
): PagedTopLevelData {
  return {
    data: entities.map(toJsonApi),
    links: [],
    meta: { totalRecords: entities.length },
  };
}

export const isJsonApiEntity = (value: unknown): boolean => {
  if (!value || typeof value !== "object") {
    return false;
  }

  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    if (
      proto.constructor.name === "JsonapiEntity" ||
      proto.constructor.name === "UnresolvedResourceIdentifier"
    ) {
      return true;
    }
    proto = Object.getPrototypeOf(proto);
  }
  return false;
};

/**
 * jsonapi-transformers uses decorators to register JSON:API types to JS classes.
 * However, due to lazy-loading and Typescript's absence from runtime, any entity
 * that is not constructed (`new MyEntity()`) will not run the constructor or
 * the `@entity` decorator, so the registration will not happen.
 *
 * This method allows us to ensure that a particular constructor is registered
 * before using it.
 */
export const ensureJsonapiEntityIsRegistered = (
  entityConstructor: ResourceIdentifierConstructor,
) => {
  if (!isEntityConstructorRegistered(entityConstructor)) {
    throw new Error(`JSON:API type not registered: ${entityConstructor.name}`);
  }
};
